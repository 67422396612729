import { useState, useEffect } from 'react';
import { VideoPlayer } from '../common/VideoPlayer';
import { Modal, Space, Tabs, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { Company, TeamMember } from '../../domain/types/Company';
import { CompanyFileCategories } from '../../domain/types/Company';
import { DocsViewer } from '../common/DocsViewer';
import { ImageViewer } from './ImageViewer';
import { DEFAULT_IMG } from '../common/constants';
import { companySiteProperties } from '../../data/services/common/siteDataService';
import {
  displayDateFormatter,
  getlabelsByValues,
} from '../../data/services/explore/converters';
import { convertNumbertoCurrency } from '../common/utils';
import SocialIcons from './SocialIconsView';
import { BLANK_IMG } from '../common/asset_paths';
import { getStorageImageUrls } from '../../data/db/FireStoreApi';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

export interface IExpandedCompanyViewProps {
  company: Company;
  cardStatusAction?: () => JSX.Element;
}

export default function ExpandedCompanyView(props: IExpandedCompanyViewProps) {
  const { company } = props;
  const maxLines = 4; // Number of lines to initially display
  const [expanded, setExpanded] = useState(false);
  const {
    diversity_designation,
    arr_range,
    minority_designation,
    industry_type,
  } = companySiteProperties ?? {};

  const [pitchDeckImageFiles, setPitchDeckImageFiles] = useState<string[]>([]);
  useEffect(() => {
    if (company?.pitchDeckImageFiles?.length) {
      getStorageImageUrls(company?.pitchDeckImageFiles).then((data) => {
        setPitchDeckImageFiles(data);
      });
    }
  }, [company?.pitchDeckImageFiles]);

  const companyAbout = company?.about ? company?.about : '';

  const companyFiles = company?.companyFiles ? company?.companyFiles : [];
  const [zoomLevel, setZoomLevel] = useState(100); // Initialize zoomLevel as 100%

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleFullscreen = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const zoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 25, 200)); // Cap zoom at 200%
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 25, 100)); // Cap zoom out at 100%
  };
  const pitchDeckUrls: string[] = companyFiles
    .filter((f) => f.category === CompanyFileCategories.pitchDeck && f.url)
    .map((file) => file.url!!);
  // executive summary
  // const executiveSummaryUrls: string[] = companyFiles
  //   .filter(
  //     (f) => f.category === CompanyFileCategories.executiveSummary && f.url,
  //   )
  //   .map((file) => file.url!!);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const getUrl = (url) => {
    if (url && (url?.indexOf('http') === -1 || url.indexOf('www.') === 0)) {
      return `https://${url}`;
    } else {
      return url;
    }
  };
  const IntroView = () => {
    let companyInro = company?.companyFiles?.find(
      (cf) => cf.category === 'companyIntro',
    );

    const displayText = expanded
      ? companyAbout
      : companyAbout.slice(0, maxLines * 100); // Assuming 100 characters per line
    return (
      <div>
        <div className="about_info ">
          {displayText}
          {companyAbout.length > 400 && (
            <span>
              {expanded ? (
                <a role="button" href="#!" onClick={toggleExpand}>
                  {' '}
                  Show Less
                </a>
              ) : (
                <>
                  ...
                  <a role="button" href="#!" onClick={toggleExpand}>
                    Show More
                  </a>
                </>
              )}
            </span>
          )}
        </div>
        {!companyInro ? (
          <></>
        ) : (
          <div>
            <VideoPlayer url={companyInro?.url}></VideoPlayer>{' '}
          </div>
        )}

        {CompanyInvestmentView(props)}
        <div className="comapany-user-list">
          <h3 className="Secondary-textcolor soft_green">Industry</h3>
          <Space size={8} wrap className="tag_lists">
            {getlabelsByValues(company?.industries, industry_type?.options).map(
              (label: any, index: number) => (
                <Tag key={index}>
                  <div className="tag_text">{label}</div>
                </Tag>
              ),
            )}
          </Space>

          <h3 className="Secondary-textcolor soft_green">Company Info</h3>

          <div className="list_group">
            <div className="text-group">
              <label htmlFor="">Business model</label>
              <span>
                {company &&
                  company?.businessModal &&
                  company?.businessModal.map((item: any, index) => (
                    <span key={index}>{(index ? ', ' : '') + item}</span>
                  ))}
              </span>
            </div>
            <div className="text-group">
              <label htmlFor="">Founder has had an exit</label>
              <span>{company?.isFounderHasHadAnExit ? 'Yes' : 'No'}</span>
            </div>
            <div className="text-group">
              <label htmlFor="">Diversity designation</label>
              {!company?.diversityDesignation ? (
                <></>
              ) : (
                getlabelsByValues(
                  [company?.diversityDesignation],
                  diversity_designation.options,
                )?.map((label: string, index: number) => (
                  <span key={index}>{label}</span>
                ))
              )}
            </div>
            <div className="text-group">
              <label htmlFor="">Annual Revenue</label>
              {!company?.annualRecurringRevenue ? (
                <></>
              ) : (
                getlabelsByValues(
                  [company?.annualRecurringRevenue],
                  arr_range.options,
                ).map((label: string, index: number) => (
                  <span key={index}>{label}</span>
                ))
              )}
            </div>
            <div className="text-group">
              <label htmlFor="">Accelerator program graduate</label>
              <span>{company?.acceleratorProgramGraduate ? 'Yes' : 'No'}</span>
            </div>
            <div className="text-group">
              <label htmlFor="">Minority designation</label>
              {!company?.minorityDesignation ? (
                <></>
              ) : (
                getlabelsByValues(
                  [company?.minorityDesignation],
                  minority_designation.options,
                ).map((label: string, index: number) => (
                  <span key={index}>{label}</span>
                ))
              )}
            </div>
            <div className="text-group">
              {company?.city ? (
                <>
                  {' '}
                  <label htmlFor="">City</label> <span>{company?.city}</span>{' '}
                </>
              ) : (
                <></>
              )}{' '}
            </div>
            <div className="text-group">
              {company?.state ? (
                <>
                  {' '}
                  <label htmlFor="">State</label> <span> {company?.state}</span>
                </>
              ) : (
                <></>
              )}{' '}
            </div>
            <div className="text-group">
              {company?.country ? (
                <>
                  {' '}
                  <label htmlFor="">Country</label>
                  <span> {company?.country}</span>{' '}
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="text-group">
              <label htmlFor="">Website</label>
              <Link
                target="blank"
                to={getUrl(
                  company?.websiteUri === 'https://null'
                    ? ''
                    : company?.websiteUri,
                )}
              >
                <span>
                  {' '}
                  {company?.websiteUri === 'https://null'
                    ? ''
                    : company?.websiteUri}{' '}
                </span>
              </Link>
            </div>
            <div className="text-group">
              <label htmlFor="">Number of employees</label>
              <span>{company?.employeeCount}</span>
            </div>
          </div>

          <h3 className="Secondary-textcolor">
            Social <span>Links</span>
          </h3>
          <div className="social_icons">
            {company &&
            company?.socialLinks &&
            company?.socialLinks?.length !== 0 ? (
              <>
                {' '}
                <SocialIcons iconsList={company?.socialLinks}></SocialIcons>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  };

  const teamsView = () => {
    return (
      <>
        <div className="team_view">
          {/* {JSON.stringify(company?.teamMembers)} */}
          {company?.teamMembers?.length !== 0 ? (
            <>
              {company?.teamMembers?.map((tm: TeamMember, index: number) => {
                return (
                  <a key={index} href={getUrl(tm.socialLink)} target="blank">
                    <div className="team_card" key={`team-${index}`}>
                      <div className="team_profile_img">
                        {tm.photoUrl ? (
                          <img alt="" src={tm.photoUrl} />
                        ) : (
                          <img alt="" src={BLANK_IMG} />
                        )}
                      </div>

                      <div className="teammember-list">
                        <h2>{tm.displayName}</h2>

                        <h3 className="">{tm?.title}</h3>
                      </div>
                    </div>
                  </a>
                );
              })}
            </>
          ) : (
            <>No Data</>
          )}
        </div>
        {CompanyInvestmentView(props)}
      </>
    );
  };

  const pitchDeckView = () => {
    return (
      <>
        <div className=" videoWrapper  content-center preview-docs-view">
          {/* new */}
          {company?.pitchDeckImageFiles?.length ? (
            <>
              <div>
                <ImageViewer
                  imageUrls={pitchDeckImageFiles}
                  onFullscreen={handleFullscreen}
                />
              </div>
            </>
          ) : (
            <DocsViewer filesUrls={pitchDeckUrls}></DocsViewer>
          )}
        </div>
        {CompanyInvestmentView(props)}
      </>
    );
  };

  const items = [
    {
      label: 'Pitch Deck',
      key: '1',
      children: pitchDeckView(),
    },
    {
      label: 'Overview',
      key: '2',
      children: IntroView(),
    },
    {
      label: 'Team',
      key: '3',
      children: teamsView(),
    },
    // {
    //   label: 'Executive Summary',
    //   key: '4',
    //   children: (
    //     <div className=" videoWrapper  content-center preview-docs-view">
    //       {!company?.companyFiles?.length ? (
    //         <></>
    //       ) : (
    //         <DocsViewer
    //           filesUrls={executiveSummaryUrls}
    //         ></DocsViewer>
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //     label: 'Data Room',
    //     key: '5',
    //     children: dealRoomView(),
    // },
  ];

  return (
    <>
      <div className="side-view-comapny">
        <div className="cvr_title">
          <h2>{company?.name}</h2>
        </div>
        <Tabs defaultActiveKey="1" items={items} />
      </div>

      <Modal
        title="Pitch Deck"
        open={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        className="full_view_screen"
      >
        <div className={`zoom-${zoomLevel} pitchdeck-fullview slim_scrollbar`}>
          {pitchDeckImageFiles.map((url, index) => (
            <img
              className="pitchdeck-fullview-img"
              key={index}
              src={url}
              alt="pitch deck"
              style={{ width: '100%', height: 'auto' }}
            />
          ))}
        </div>

        <div className="control_overlay">
          <div className="popup_zoom_contral">
            <ZoomInOutlined onClick={zoomIn} />
            <ZoomOutOutlined onClick={zoomOut} />
          </div>
        </div>
        {/* <PinchZoom imageUrls={pitchDeckImageFiles} /> */}
      </Modal>
    </>
  );
}

export function CompanyInvestmentView(props: IExpandedCompanyViewProps) {
  const { arr_range, pre_funding_valuation } = companySiteProperties;
  const { company } = props;
  return (
    <>
      <div className="profile_hide">
        <div className="cvr_flex_wrapping">
          <div className="logo-company">
            <img
              src={company?.logoUri ? company?.logoUri : DEFAULT_IMG}
              alt="company logo"
            />
          </div>

          <div className="colounm_two">
            <h3 className="Secondary-textcolor soft_green">
              Investment Details
            </h3>
            <div className="list_group">
              <div className="text-group">
                <label>Round</label>
                <>
                  {!company?.fundingRound ? (
                    <></>
                  ) : (
                    getlabelsByValues(
                      [company?.fundingRound],
                      companySiteProperties?.funding_round?.options,
                    ).map((label: string, index: number) => (
                      <span key={index}>{(index ? ', ' : '') + label}</span>
                    ))
                  )}
                </>
              </div>

              <div className="text-group">
                <label>Round Close Date</label>
                <span>{displayDateFormatter(company?.closingDate)}</span>
              </div>

              <div className="text-group">
                <label>Seeking Amount</label>
                <span>{convertNumbertoCurrency(company?.seekingAmount)}</span>
              </div>
              <div className="text-group">
                <label>Investment Structure</label>
                <span>
                  {getlabelsByValues(
                    company?.structures,
                    companySiteProperties?.company_structure?.options,
                  ).map((label: string, index: number) => (
                    <span key={index}>{(index ? ', ' : '') + label}</span>
                  ))}
                </span>
              </div>
              <div className="text-group">
                <label>Minimum Investment</label>
                <span>
                  {company && company?.minimumInvestment
                    ? '$' +
                      String(company?.minimumInvestment)?.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        '$1,',
                      )
                    : ''}
                </span>
              </div>
              <div className="text-group">
                <label>Syndicate Allowed</label>
                <span>
                  {company?.allowSyndicateDeals ? 'Allowed' : 'Not allowed'}
                </span>
              </div>
              <div className="text-group">
                <label>Status</label>
                <span>{company?.status ? 'Raising' : 'Not Raising'}</span>
              </div>
            </div>
            <h3 className="Secondary-textcolor soft_green">
              Company Financials
            </h3>
            <div className="list_group">
              <div className="text-group">
                <label>Annual Revenue</label>
                <span>
                  {company?.annualRecurringRevenue
                    ? getlabelsByValues(
                        [company?.annualRecurringRevenue],
                        arr_range.options,
                      )
                    : ''}
                </span>
              </div>
              <div className="text-group">
                <label>Pre-Funding Valuation</label>
                <span>
                  {company?.preFundingValuation
                    ? getlabelsByValues(
                        [company?.preFundingValuation],
                        pre_funding_valuation.options,
                      )
                    : ''}
                </span>
              </div>
              <div className="text-group">
                <label>Funding Raised to Date </label>
                <span>
                  {convertNumbertoCurrency(company?.fundingRaisedToDate)}
                </span>
              </div>
            </div>
            <div className="org-status-action">
              {props.cardStatusAction ? props.cardStatusAction() : <></>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
