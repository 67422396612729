import { create } from 'zustand';

interface ConversationsState {
  conversations: {};
  addConversationMsgs: (convoId, convoMsgs) => void;
}

const useConversationsStore = create<ConversationsState>()((set) => ({
  conversations: {},
  addConversationMsgs: (convoId, convoMsgs) => {
    set((state) => ({
      conversations: { ...state.conversations, [convoId]: convoMsgs },
    }));
  },
}));

export default useConversationsStore;
