import * as React from 'react';
import { Space, Tabs, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { InvestmentFirm } from '../../domain/types/Investor';
import { useState } from 'react';

// need to check
import { replaceWithBr } from '../common/utils';
import { getlabelsByValues } from '../../data/services/explore/converters';
import { investorSiteProperties } from '../../data/services/common/siteDataService';
import { DEFAULT_FIRM_IMG } from '../common/constants';
import { BLANK_IMG } from '../common/asset_paths';
import SocialIcons from './SocialIconsView';
import { TeamMember } from '../../domain/types/Investor';

export interface IExpandedInvestmentFirmViewProps {
  investmentFirm: InvestmentFirm;
  cardStatusAction?: () => JSX.Element;
  showSocialIcons?: boolean;
}

export function ExpandedInvestmentFirmView(
  props: IExpandedInvestmentFirmViewProps,
) {
  const { investmentFirm } = props;
  const [expanded, setExpanded] = useState(false);
  const maxLines = 4; // Number of lines to initially display
  const { industry_type } = investorSiteProperties;

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const getUrl = (url) => {
    if (url && (url?.indexOf('http') === -1 || url.indexOf('www.') === 0)) {
      return `https://${url}`;
    } else {
      return url;
    }
  };
  const firmOverView = () => {
    const displayText = expanded
      ? investmentFirm?.about
      : investmentFirm?.about?.slice(0, maxLines * 100); // Assuming 100 characters per line
    return (
      <div className="inv_slide_view">
        <div className="about_info">
          {replaceWithBr(displayText)}
          {investmentFirm?.about && investmentFirm?.about?.length > 400 && (
            <span>
              {expanded ? (
                <a href="#!" onClick={toggleExpand}>
                  {' '}
                  Show Less
                </a>
              ) : (
                <>
                  ...
                  <a href="#!" onClick={toggleExpand}>
                    Show More
                  </a>
                </>
              )}
            </span>
          )}
        </div>

        {InvestorInvestmentProfile(props)}
        <h3 className="Secondary-textcolor soft_green">industries</h3>

        <Space size={8} wrap className="tag_lists">
          {getlabelsByValues(
            investmentFirm?.industries,
            industry_type.options,
          ).map((label: string, index: number) => (
            <Tag key={index}>
              <div className="tag_text"> {label}</div>
            </Tag>
          ))}
        </Space>

        <h3 className="Secondary-textcolor soft_green ">Firm</h3>

        <div className="width_div">
          <div className="list_group">
            <div className="text-group">
              {investmentFirm?.city ? (
                <>
                  <label htmlFor="">City</label>{' '}
                  <span>{investmentFirm?.city}</span>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="text-group">
              {investmentFirm?.state ? (
                <>
                  <label htmlFor="">State</label>
                  <span> {investmentFirm?.state} </span>
                </>
              ) : (
                <></>
              )}{' '}
            </div>
            <div className="text-group">
              {investmentFirm?.country ? (
                <>
                  <label htmlFor="">Country</label>{' '}
                  <span>{investmentFirm?.country}</span>{' '}
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="text-group">
              <label htmlFor="">Website</label>

              <Link
                target="blank"
                to={getUrl(
                  investmentFirm?.websiteUri === 'https://null'
                    ? ''
                    : investmentFirm?.websiteUri,
                )}
              >
                <span>
                  {investmentFirm?.websiteUri === 'https://null'
                    ? ''
                    : investmentFirm?.websiteUri}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const teamsView = () => {
    return (
      <>
        <div className="team_view">
          {/* {JSON.stringify(company?.teamMembers)} */}
          {investmentFirm?.teamMembers?.length !== 0 ? (
            <>
              {' '}
              {investmentFirm?.teamMembers?.map(
                (tm: TeamMember, index: number) => {
                  return (
                    <a href={getUrl(tm.socialLink)} target="blank">
                      <div className="team_card" key={`team-member-${index}`}>
                        <div className="team_profile_img">
                          {tm.photoUrl ? (
                            <img alt="" src={tm.photoUrl} />
                          ) : (
                            <img alt="" src={BLANK_IMG} />
                          )}
                        </div>

                        <div className="teammember-list">
                          <h2>{tm.displayName}</h2>

                          <h3 className="">{tm?.title}</h3>
                        </div>
                      </div>
                    </a>
                  );
                },
              )}
            </>
          ) : (
            <>No Data</>
          )}
        </div>
        {InvestorInvestmentProfile(props)}
      </>
    );
  };

  const items = [
    {
      label: 'Overview',
      key: '1',
      children: firmOverView(),
    },

    {
      label: 'Team',
      key: '5',
      children: teamsView(),
    },
  ];

  return (
    <>
      <div className="side-view-comapny">
        <div className="cvr_title">
          <h2>{investmentFirm?.name}</h2>
        </div>
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </>
  );
}

export function InvestorInvestmentProfile(
  props: IExpandedInvestmentFirmViewProps,
) {
  const { investmentFirm } = props;
  const { assets_under_management, investor_type } = investorSiteProperties;
  return (
    <>
      <div className="profile_hide">
        <div className="cvr_flex_wrapping">
          <div className="logo-company">
            <img
              src={
                investmentFirm?.logoUri
                  ? investmentFirm?.logoUri
                  : DEFAULT_FIRM_IMG
              }
              alt="investmentFirm logo"
            />
          </div>

          <div className="colounm_two">
            <h3 className="Secondary-textcolor soft_green">
              Investment Profile
            </h3>
            <div className="list_group">
              <div className="text-group">
                <label>Investor Type</label>

                {!investmentFirm?.investorType ? (
                  <></>
                ) : (
                  getlabelsByValues(
                    [investmentFirm?.investorType],
                    investor_type.options,
                  ).map((label: string, index: number) => (
                    <span key={`${label}-${index}`}>{label}</span>
                  ))
                )}
              </div>

              <div className="text-group">
                <label>Assets Under Management</label>

                {!investmentFirm?.assetsUnderManagement ? (
                  <></>
                ) : (
                  getlabelsByValues(
                    [investmentFirm?.assetsUnderManagement],
                    assets_under_management.options,
                  ).map((label: string, index: number) => (
                    <span key={`${label}-${index}`}>{label}</span>
                  ))
                )}
              </div>
            </div>

            <h3 className="Secondary-textcolor soft_green">
              Investment Activities
            </h3>

            <div className="list_group">
              <div className="text-group">
                <label>Syndicate Deals</label>
                <span>{investmentFirm?.interestedInSyndicateDeals}</span>
              </div>

              <div className="text-group">
                <label>Companies Funded</label>
                <span>{investmentFirm?.companiesFunded}</span>
              </div>

              <div className="text-group">
                <label>Active Investments</label>
                <span>{investmentFirm?.activeInvestments}</span>
              </div>

              <div className="text-group">
                <label>Exits</label>
                <span>{investmentFirm?.exits}</span>
              </div>

              <div className="text-group">
                <label>Venture Funds</label>
                <span>{investmentFirm?.ventureFunds ?? ''}</span>
              </div>
            </div>
            <div className="footer_social_icons">
              <h3 className="Secondary-textcolor">
                Social <span>Links</span>{' '}
              </h3>
              <div className="social_icons">
                {investmentFirm &&
                investmentFirm?.socialLinks &&
                investmentFirm?.socialLinks?.length !== 0 ? (
                  //need to generte new comp and remove this
                  <SocialIcons
                    iconsList={investmentFirm?.socialLinks}
                  ></SocialIcons>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="org-status-action">
              {props.cardStatusAction ? props.cardStatusAction() : <></>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
