import * as React from 'react';

import { Card, Col, Row } from 'antd';
export interface IReportKeyValueProps {
  reportData: any[];
}

export function ReportKeyValue(props: IReportKeyValueProps) {
  if (!props.reportData || props.reportData.length === 0) {
    return <div> No Data </div>;
  }

  const ObjView = (ObjectValue) => {
    return (
      <>
        {Object.keys(ObjectValue).map((key) => {
          let value = ObjectValue[key] ? ObjectValue[key] : 'No Value';
          let label = key ? key.toString().split('_').join(' ') : 'No Label';

          return (
            <Col span={12}>
              <div className="gap-5">
                <Card size="small" bordered={false}>
                  <div style={{ textAlign: 'center' }}>
                    <h1>{value} </h1>
                    <h4> {label}</h4>
                  </div>
                </Card>
              </div>
            </Col>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <Row>
        {props.reportData.map((data: any) => {
          return ObjView(data);
        })}
      </Row>
    </div>
  );
}
