import {
  UserCredential,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User,
} from 'firebase/auth';
import FirebaseApi from '../../../../api/firebaseApi';
const auth = FirebaseApi.auth;

/*
    Wraper function to Firebase sign in with email and password,
    Should be able to swap out with any other auth provider 
*/
export function signInWithEmailPassword({
  email,
  password,
}): Promise<any | UserCredential> {
  return signInWithEmailAndPassword(auth, email, password);
}

export function onUserAuthStateChange(callback: (user: User | null) => void) {
  onAuthStateChanged(auth, callback);
}

export function signOutUser(): Promise<void> {
  return signOut(auth);
}
