import { create } from 'zustand';
import { Profile } from '../../domain/types/Profile';
import { ConnectionStatus } from '../../domain/types/Connection';

interface ProfileState {
  profile: Profile | null;
  isAuthenticated: boolean;
  orgConnections: any[];
  unReadConvoIds: string[];
  setProfile: (userProfile: Profile) => void;
  removeProfile: () => void;
  setAuthenticated: (isAuthenticated: boolean) => void;
  setOrgConnections: (orgConnections: any[]) => void;
  setUnReadConvoIds: (unReadConvoIds: string[]) => void;
  removeUnReadConvoId: (unReadConvoId: string) => void;
}

const useProfileStore = create<ProfileState>()((set) => ({
  profile: null,
  isAuthenticated: false,
  orgConnections: [],
  unReadConvoIds: [],
  setProfile: (userProfile) => {
    set((state) => ({ profile: userProfile }));
  },
  removeProfile: () => {
    set((state) => ({ profile: null }));
  },

  setAuthenticated: (isAuthenticated) => {
    set((state) => ({ isAuthenticated }));
  },

  setOrgConnections: (orgConnections) => {
    let filterOrgConnections = orgConnections.filter(
      (convo) =>
        !(
          convo.connectionStatus === ConnectionStatus.CANCELLED ||
          convo.isActive === false
        ),
    );

    set((state) => ({ orgConnections: filterOrgConnections }));
  },
  setUnReadConvoIds: (unReadConvoIds) => {
    let val: string[] = Array.from(new Set(unReadConvoIds));
    set((state) => ({ unReadConvoIds: val }));
  },

  removeUnReadConvoId: (unReadConvoId) => {
    set((state) => ({
      unReadConvoIds: state.unReadConvoIds.filter((id) => id !== unReadConvoId),
    }));
  },
}));

export default useProfileStore;
