import { AppNotification } from '../../../domain/types/Notification';
import { FirestoreCollectionApi } from '../../db/FireStoreApi';

//a013aXIunqeyfKnpL3giOo6gIG92/notifications
const usersCollectionService = new FirestoreCollectionApi<any>('users');

const notificationsCollections = (userId: string) =>
  usersCollectionService.getSubcollectionApi<AppNotification>(
    userId,
    `notifications`,
  );

export function getNotificationsByUserId(
  userId: string,
): Promise<AppNotification[]> {
  return notificationsCollections(userId).getAllDocs();
}
