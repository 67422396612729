import { WVP_LOGO_NEW } from '../../v1/presentation/common/constants';
import { Button, Form, Input } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useAuth, useInvitation } from '../../api/features/auth';
// to use later
import toast from '../../v1/presentation/common/Toast';
// old to remove
// import { toasty } from '../../shared/utils';

export interface IOnBoardingProps {}

export default function OnBoarding(props: IOnBoardingProps) {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token') ?? undefined;
  const invitation = useInvitation(token);
  const {
    //  signInWithGoogle
    signUpWithEmailPassword,
  } = useAuth();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    if (invitation) {
      const msg = await signUpWithEmailPassword({
        email: invitation.email,
        password: values.password,
        firstName: invitation.firstName,
        lastName: invitation.lastName,
        invitationId: invitation.id,
      });
      // old
      // toasty[msg.type](msg.title, msg.text);
      //new  to change later
      if (msg.text) {
        toast.info(msg.title, msg.text);
      }
      window.location.href = window.location.origin + '/login';
    }
  };

  // const GoogleSvg = () => (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     viewBox="0 0 48 48"
  //     width="30px"
  //     height="30px"
  //   >
  //     <path
  //       fill="#FFC107"
  //       d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
  //     />
  //     <path
  //       fill="#FF3D00"
  //       d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
  //     />
  //     <path
  //       fill="#4CAF50"
  //       d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
  //     />
  //     <path
  //       fill="#1976D2"
  //       d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
  //     />
  //   </svg>
  // );

  // const GoogleIcon = (props: Partial<CustomIconComponentProps>) => (
  //   <Icon component={GoogleSvg} {...props} />
  // );

  if (!invitation) {
    return (
      <div key={1} className="login_page">
        <div className="header_text">
          <img className="logo_asset" src={WVP_LOGO_NEW} alt="WealthVP" />
          <h3>Invalid invitation link. Please contact your administrator.</h3>
        </div>
      </div>
    );
  }

  return (
    <div key={1} className="login_page">
      <div className="header_text">
        <img className="logo_asset" src={WVP_LOGO_NEW} alt="WealthVP" />
        <div>
          <h3>
            You have been invited to
            <span className="">{invitation.orgName}</span>
          </h3>
        </div>
        <h2>New account</h2>
      </div>
      <div className="form_input">
        <Form
          form={form}
          name="signup-form"
          className="signup-form"
          onFinish={onFinish}
        >
          <Form.Item name="email">
            <Input
              size="large"
              defaultValue={invitation.email}
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Enter your email address..."
              autoComplete="false"
              disabled
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Password mandatory' }]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Enter a password..."
              autoComplete="new-password"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              SIGN UP
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
