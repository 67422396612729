import * as React from 'react';
import { ExploreCardData } from '../../data/services/explore/ExploreCardData';
import {
  companySiteProperties,
  investorSiteProperties,
  realestateSiteProperties,
  SiteDataOption,
  SiteDataProperty,
} from '../../data/services/common/siteDataService';
import { Button, Dropdown, Space } from 'antd';
import {
  BorderOutlined,
  CaretDownOutlined,
  CheckSquareOutlined,
} from '@ant-design/icons';
import useExploreStore from '../../data/appState/exploreStore';

const propertyNameField = {
  diversity_designation: 'diversityDesignation',
  funding_round: 'fundingRound',
  industry_type: 'industries',
  minority_designation: 'minorityDesignation',
  arr_range: 'annualRecurringRevenue',
  business_model: 'businessModal',
  company_structure: 'structures',
  pre_funding_valuation: 'preFundingValuation',

  //RealestateOpportunity Properties
  assets_under_management: 'assetsUnderManagement',
  property_sector: 'sectors',
  investment_type: 'investmentType',
  investment_strategy: 'investmentStrategy',
  investment_structure: 'investmentStructure',
  return_structure: 'returnStructure',
  anticipated_term: 'anticipatedTerm',
  tax_advantage: 'taxAdvantage',

  //Investor Properties

  // assets_under_management:"assetsUnderManagement"
  investor_type: 'investorType',
};

export interface IExploreFiltersProps {
  eploreCards: ExploreCardData[];
  exploreType: string;
}

type SiteDataPropertyWithCount = SiteDataProperty & {
  count: number;
  options: SiteDataOptionWithCount[];
};
type SiteDataOptionWithCount = SiteDataOption & { count: number };

export function ExploreFilters({
  eploreCards,
  exploreType,
}: IExploreFiltersProps) {
  const {
    selectedOptions,
    addSelectedOption,
    removeSelectedOption,
    clearSelectedOptions,
  } = useExploreStore();

  let exploreProperties: SiteDataProperty[] = [];

  switch (exploreType) {
    case 'companies':
      exploreProperties = Object.values(companySiteProperties);
      break;
    case 'realestates':
      exploreProperties = Object.values(realestateSiteProperties);
      break;
    case 'investors':
      exploreProperties = Object.values(investorSiteProperties);
      break;
    default:
      break;
  }

  let explorePropertiesWithCount: SiteDataPropertyWithCount[] = [];

  for (let property of exploreProperties) {
    let propertyCount = 0;

    let propertyOptions: SiteDataOptionWithCount[] = [];

    for (let option of property.options) {
      let propertyValueCards = eploreCards.filter((card) => {
        const { orgData } = card;
        let propertyNameFieldKey = propertyNameField[property.name];

        let hasValue = orgData[propertyNameFieldKey] === option.value;

        if (
          !hasValue &&
          orgData[propertyNameFieldKey] &&
          orgData[propertyNameFieldKey].indexOf
        ) {
          hasValue = orgData[propertyNameFieldKey].indexOf(option.value) !== -1;
        }

        return hasValue;
      });
      propertyCount += propertyValueCards.length;
      propertyOptions.push({ ...option, count: propertyValueCards.length });
    }
    explorePropertiesWithCount.push({
      ...property,
      options: propertyOptions,
      count: propertyCount,
    });
  }

  return (
    <div className="filter_meun">
      <div className="filter_lists">
        <Space wrap>
          {explorePropertiesWithCount
            .filter((p) => p.count > 0)
            .map((property: SiteDataPropertyWithCount, index: number) => (
              <div key={`filterdropdown-${index}`}>
                <FilterDropDown
                  property={property}
                  selectedOptions={selectedOptions}
                  onSelect={(option) => {
                    addSelectedOption(option);
                  }}
                  onUnSelect={(option) => {
                    console.log('onUnSelect', option);
                    removeSelectedOption(option);
                  }}
                />
              </div>
            ))}
        </Space>
      </div>

      <div className="filter_selected">
        <Space wrap>
          {selectedOptions.map((option, index: number) => {
            return (
              <Button
                key={`buttons-${index}`}
                onClick={(e) => {
                  e.preventDefault();
                  removeSelectedOption(option);
                }}
              >
                {option.label}

                <span className="close_btn">&#x2715;</span>
              </Button>
            );
          })}
          {selectedOptions.length > 0 ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                clearSelectedOptions();
                // selectedOptions.map((option) => {

                // });
              }}
            >
              Reset
            </Button>
          ) : (
            <></>
          )}
        </Space>
      </div>
    </div>
  );
}

export interface IFilterDropDownProps {
  property: SiteDataPropertyWithCount;
  selectedOptions: SiteDataOption[];
  onSelect: (option: SiteDataOption, property: SiteDataProperty) => void;
  onUnSelect: (option: SiteDataOption, property: SiteDataProperty) => void;
}

export function FilterDropDown({
  property,
  selectedOptions,
  onSelect,
  onUnSelect,
}: IFilterDropDownProps) {
  let propertyOptions = property.options as SiteDataOptionWithCount[];

  const items = propertyOptions
    .filter((p) => p.count > 0)
    .map((value) => {
      let indexOfSelectedOption = selectedOptions.findIndex(
        (option) => option.value === value.value,
      );

      return {
        label: (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            type="link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (indexOfSelectedOption === -1) {
                if (onSelect) onSelect(value, property);
              } else {
                if (onUnSelect) onUnSelect(value, property);
              }
            }}
          >
            <Space>
              {indexOfSelectedOption !== -1 ? (
                <CheckSquareOutlined />
              ) : (
                <BorderOutlined />
              )}
              {value.label + ` (${value.count})`}
            </Space>
          </a>
        ),
        key: value.value,
      };
    });

  return (
    <div className="drop-down-btn" key={property.name}>
      {/* <span>{property.name}</span> */}
      <Dropdown menu={{ items }}>
        <Button onClick={(e) => e.preventDefault()}>
          <Space>
            {property.label}
            <CaretDownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
}
