import * as React from 'react';

interface IPrivacyPolicyProps {}

const PrivacyPolicyPage: React.FunctionComponent<IPrivacyPolicyProps> = (
  props,
) => {
  return <h1>Privacy Policy</h1>;
};

export default PrivacyPolicyPage;
