import { Company as BaseCompany, Member } from '@wealthvp/javascript-sdk';

export type Company = BaseCompany & {
  isActive: boolean;
  isHidden?: boolean;
  pitchDeckImageFiles?: string[];
};

export enum CompanyFileCategories {
  intro = 'companyIntro',
  pitchDeck = 'pitchDeck',
  executiveSummary = 'executiveSummary',
}

export type CompanyFile = {
  category?: string;
  size?: number;
  url?: string;
};

export type TeamMember = {
  displayName?: string;
  photoUrl?: string;
  socialLink?: string;
  title?: string;
};

export type CompanyMember = Member & { db_ref_id: string };
