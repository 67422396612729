import React, { useState, useEffect } from 'react';

interface ColorCardProps {
  name: string;
}

//const colors = ['#D2D5FC', '#3E9C78', '#E1DECF'];
const colors = ['color1', 'color2', 'color3'];
let colorIndex = 0; // To keep track of the current index

const ColorCard: React.FC<ColorCardProps> = ({ name }) => {
  // const [background, setBackgroundColor] = useState<string>(
  //   colors[colorIndex],
  // );

  const [colorClass, setColorClass] = useState<string>(colors[colorIndex]);

  useEffect(() => {
    // Update the background color sequentially
    setColorClass(colors[colorIndex]);
    colorIndex = (colorIndex + 1) % colors.length; // Move to the next color
  }, []);

  return (
    <div className={`color_card ${colorClass}  `}>
      <h2>{name}</h2>
    </div>
  );
};

export default ColorCard;
