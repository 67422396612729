import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useProfileStore from '../../data/appState/profileStore';
import { RealEstateOpportunity } from '../../domain/types/RealEstateOpportunity';
import FileUploader from '../common/FileUploader';
import { realestateSiteProperties } from '../../data/services/common/siteDataService';
import {
  createOpportunity,
  getOpportunityByFirmId,
  updateOpportunity,
} from '../../data/services/realEstate/realEstateOpportunityService';
import toast from '../common/Toast';
import { BackArrow, UploadCloudIcon } from '../common/utilComponents';

export interface IRealEstateCreateOpportunityProps {}

export default function RealEstateCreateOpportunity(
  props: IRealEstateCreateOpportunityProps,
) {
  window.scrollTo(0, 0);
  const navigateTo = useNavigate();
  const [form] = Form.useForm<RealEstateOpportunity>();
  const { oid: opportunityId } = useParams();
  const [pictureUrls, setPictureUrls] = useState<string[]>([]);
  const { profile } = useProfileStore();
  const [opportunity, setOpportunity] = useState<RealEstateOpportunity>();
  useEffect(() => {
    if (opportunityId) {
      getOpportunityByFirmId(opportunityId).then(
        (data: RealEstateOpportunity) => {
          setOpportunity(data);
          form.setFieldsValue(data);
          if (data?.pictureUrls?.length !== 0)
            setPictureUrls(data?.pictureUrls ?? []);
        },
      );
    }
  }, [opportunityId, form]);

  const onFinish = (values) => {
    let updatedResponsor = { ...opportunity, ...values };
    updatedResponsor.pictureUrls = pictureUrls;
    if (opportunityId) {
      updateOpportunity({ ...updatedResponsor, id: opportunityId }).then(() => {
        navigateTo('/opportunities');
        toast.success('opportunity updated', '');
      });
    } else if (profile?.organisationId) {
      updatedResponsor['sponserId'] = profile.organisationId;
      createOpportunity(updatedResponsor).then((res) => {
        debugger;
        navigateTo('/opportunities');
      });
    }
  };

  const {
    property_sector,
    investment_strategy,
    investment_structure,
    return_structure,
    tax_advantage,
    anticipated_term,
    investment_type,
  } = realestateSiteProperties ?? {};

  const estatePhotos = () => {
    if (!pictureUrls || !pictureUrls.length)
      return (
        <div style={{ height: '244px' }}>
          <h2>No Photos Available</h2>
          <p> Please Upload Some Photos.</p>
        </div>
      );
    return pictureUrls?.reverse()?.map((pic: string, i: number) => {
      return (
        <div key={`pic-${i}`} className="estate-img-wrap">
          <CloseCircleOutlined
            className="close-icon"
            title="Remove Image"
            onClick={() => {
              removeImg(pic);
            }}
          />
          <img src={pic} className="estate-img" alt="estate-img" />
        </div>
      );
    });
  };

  const removeImg = (imgurl) => {
    let index = pictureUrls.indexOf(imgurl);
    if (index !== -1) {
      pictureUrls.splice(index, 1);
      setPictureUrls([...pictureUrls]);
    }
  };
  return (
    <div className="body_layout">
      <Form
        form={form}
        name="profile-edit-form"
        onFinish={onFinish}
        layout="vertical"
      >
        <div className="sub_header">
          <div className="back_icon">
            <BackArrow onClick={() => navigateTo(-1)} />
          </div>
        </div>

        <div className="ou-grid-two  wvp-p-flex-d">
          <div className="estate-img-space">
            <h3> Photos </h3>

            <Row>
              <Col xs={24} sm={24} md={8} lg={5} xl={5}>
                <div className="img-add-space">
                  <div className="upload-container">
                    <label className="upload-box">
                      <div className="upload-icon">
                        {' '}
                        <UploadCloudIcon />
                      </div>
                      <div>
                        <p>Select a file</p>
                        <p className="file-size-info">
                          PDF file size no more than 10MB
                        </p>
                      </div>
                      <FileUploader
                        onUploadComplete={(e) => {
                          if (e) setPictureUrls([...pictureUrls, e.url]);
                        }}
                      ></FileUploader>
                    </label>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} md={16} lg={19} xl={19}>
                <div className="img-list-space">{estatePhotos()}</div>
              </Col>
            </Row>
          </div>

          {/* <div className='scroll-h-img'>
                      {pictureUrls && pictureUrls.length !== 0 ? <>
                          {pictureUrls?.map((pic: any) => {

                              return (<div className='div-img'><img src={pic} /></div>)
                          })}</> : <></>}

                  </div> */}
          <div className="grid-3">
            {/* <Form.Item
                          label="Photo"
                      >
                          <FileUploader onUploadComplete={(e) => { setPictureUrls([...pictureUrls, e.url]) }}></FileUploader>




                      </Form.Item> */}
            {/* <Form.Item
                          name="name"
                          label="Name"

                          rules={[
                              {
                                  required: true,
                                  message: "Please Enter Name",
                              },
                          ]}
                      >

                          <Input onChange={(e: any) => setName(e?.target?.value)} />
                      </Form.Item> */}
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter  name',
                },
              ]}
            >
              <Input size="large" placeholder="name" />
            </Form.Item>
            <Form.Item
              name="sectors"
              label="Sectors"
              rules={[
                {
                  required: true,
                  message: 'Please select sectors',
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select"
                options={property_sector?.options ?? []}
              />
            </Form.Item>
            <Form.Item
              name="investmentStrategy"
              label="Investment Strategy"
              rules={[
                {
                  required: true,
                  message: 'Please select Investment Strategy',
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select"
                options={investment_strategy?.options ?? []}
              />
            </Form.Item>

            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: 'Please enter City',
                },
              ]}
            >
              <Input name="city" placeholder="City" />
            </Form.Item>

            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message: 'Please enter State',
                },
              ]}
            >
              <Input name="state" placeholder="State" />
            </Form.Item>

            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: 'Please enter country',
                },
              ]}
            >
              <Input name="country" placeholder="Country" />
            </Form.Item>

            <Form.Item
              name="minimumInvestment"
              label="Minimum Investment"
              rules={[
                {
                  required: true,
                  message: 'Please enter Minimum Investment',
                },
              ]}
              hasFeedback
            >
              <InputNumber
                prefix="$"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                placeholder="Minimum Investment"
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="totalDealSize"
              label="Total Deal Size"
              rules={[
                {
                  required: true,
                  message: 'Please enter totalDealSize',
                },
              ]}
              hasFeedback
            >
              <InputNumber
                prefix="$"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                placeholder="Total Deal Size"
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="investmentStructure"
              label="Investment Structure"
              rules={[
                {
                  required: true,
                  message: 'Please select Investment Strategy',
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select"
                options={investment_structure?.options ?? []}
              />
            </Form.Item>

            <Form.Item
              name="returnStructure"
              label="Return Structure"
              rules={[
                {
                  required: true,
                  message: 'Please select Investment Strategy',
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select"
                options={return_structure?.options ?? []}
              />
            </Form.Item>

            <Form.Item
              name="anticipatedTerm"
              label="Anticipated Term"
              rules={[
                {
                  required: true,
                  message: 'Please select Anticipated Term',
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select"
                options={anticipated_term?.options ?? []}
              />
            </Form.Item>
            <Form.Item
              name="taxAdvantage"
              label="Tax Advantage"
              rules={[
                {
                  required: true,
                  message: 'Please select Tax Advantage',
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select"
                options={tax_advantage?.options ?? []}
              />
            </Form.Item>
            <Form.Item
              name="investmentType"
              label="Investment Type"
              rules={[
                {
                  required: true,
                  message: 'Please select Investment Type',
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select"
                options={investment_type?.options ?? []}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                { required: true, message: 'Please enter your description' },
              ]}
            >
              <TextArea
                placeholder="description"
                style={{ height: 100, marginBottom: 16 }}
              />
            </Form.Item>
          </div>

          <div className="actions_btn">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                SAVE
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="default"
                onClick={() => navigateTo('/opportunities')}
              >
                CANCEL
              </Button>
            </Form.Item>
            {/* {opportunityId ? (
            <Form.Item>
              <Button
                type="primary"
                onClick={() => setDeleteOpportunityId(opportunityId)}
                className="bg-primary"
                style={{ float: 'right', gridColumn: '3/4' }}
                danger
                ghost
              >
                DELETE
              </Button>
            </Form.Item>
          ) : (
            <></>
          )} */}
          </div>
        </div>
        {/* <Modal
        title="Confirmation"
        open={!!deleteOpportunityId}
        onOk={() => onDeleteOppurtunity()}
        onCancel={() => setDeleteOpportunityId(null)}
      >
        <p>Are you sure to Delete Opportunity ?</p>
      </Modal> */}
      </Form>
    </div>
  );
}
