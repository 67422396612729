import * as React from 'react';
import { ExploreCardData } from '../../data/services/explore/ExploreCardData';
import ColorCard from './ColorCard';

export interface IExploreCardProps {
  cardData: ExploreCardData;
  // connectionStatus?: ConnectionStatus;
  // profile: Profile | null
  cardStatusAction?: () => JSX.Element;
  onCardSelect?: (cardData: ExploreCardData) => void;
  // onConnect?: (cardData: ExploreCardData) => void;
}

export default function ExploreCard(props: IExploreCardProps) {
  const cardData = props.cardData;

  //const navigateTo = useNavigate();

  // const isNotConnected = !props.connectionStatus
  //   || props.connectionStatus === ConnectionStatus.NOT_CONNECTED;

  const detailItemValue = (
    value?: null | string | number | string[] | number[],
  ) => {
    if (!value) return '';

    if (typeof value == 'object' && value.length) {
      return value.map((item: any, index) => (
        <span className="sub-value" key={index}>
          {(index ? ', ' : '') + item}
        </span>
      ));
    } else {
      return value;
    }
  };

  // const gotoConversation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  //   e.stopPropagation();

  //   let connectionKey = [cardData.id, props.profile?.organisationId].sort().join('_');
  //   getConvoByKey(connectionKey, ConnectionType.ORG_ORG).then((convos) => {
  //     if (convos.length > 0) {
  //       navigateTo(`/chat/${convos[0].db_ref_id}`);
  //     }

  //   })

  // }

  return (
    <div
      key={cardData?.id}
      className={'main-card ' + cardData.orgType}
      id={cardData.id}
      onClick={() => {
        if (props.onCardSelect) {
          props.onCardSelect(cardData);
        }
      }}
    >
      <div className="card-header">
        <div className="new_tag">New</div>
        <div className="container-img">
          {cardData.imageUrl ? (
            <img className="company-logo" src={cardData.imageUrl} alt="" />
          ) : (
            <ColorCard name={cardData.title} />
          )}
        </div>
      </div>
      <div className="card-content">
        <h2>{cardData.title}</h2>

        <div className="card-info">
          <div className="entity-details">
            {cardData?.detailsList
              .filter((dlItem) => dlItem?.label && dlItem?.value)
              .map((dlItem) => {
                let isValueEmpty =
                  dlItem?.value === '' ||
                  dlItem?.value === null ||
                  dlItem?.value === undefined;
                if (
                  typeof dlItem?.value === 'object' &&
                  dlItem?.value?.length === 0
                )
                  isValueEmpty = true;

                if (dlItem?.label && !isValueEmpty) {
                  return (
                    <div key={`id-${dlItem.label}`} className="list-info">
                      <span className="list_label">{dlItem?.label}</span>
                      <span className="list_value">
                        {detailItemValue(dlItem?.value)} {}
                      </span>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
          </div>
          <div className="multine-ellipsis entity-description">
            {cardData.about}
          </div>
        </div>
        <div className="conn_status">
          {props.cardStatusAction ? props.cardStatusAction() : <></>}
        </div>

        {/* {isNotConnected ? <Button

        onClick={(e) => {
          e.stopPropagation();
          console.log('Connect clicked');
          props.onConnect && props.onConnect(cardData);
        }}
      >Connect</Button>
        : <a onClick={gotoConversation}><label  >{props.connectionStatus}</label></a>} */}
      </div>
    </div>
  );
}
