import * as React from 'react';
import useProfileStore from '../../data/appState/profileStore';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Modal } from 'antd';
import { getExploreData } from '../../data/services/explore/exploreService';
import { ExploreCardData } from '../../data/services/explore/ExploreCardData';
import ExploreCard from '../components/ExploreCard';
import { useEffect, useState } from 'react';
import { ProcessState } from '../common/types';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import ExpandedCompanyView from '../components/ExpandedCompanyView';
import { Company } from '../../domain/types/Company';
import {
  ConnectionStatus,
  ConnectionType,
  ConversationType,
} from '../../domain/types/Connection';
import { RealEstateOpportunity } from '../../domain/types/RealEstateOpportunity';
import { ExpandedREOpportunity } from '../components/ExpandedREOppurtunityVIew';
import { ExpandedInvestmentFirmView } from '../components/ExpandedInvestmentFirmView';
import { InvestmentFirm } from '../../domain/types/Investor';
import {
  ConvoMessage,
  ConvoParticipant,
  createConversation,
  getConvoByKey,
} from '../../data/services/conversation/conversationService';
import { ExploreFilters } from '../components/ExploreFilters';
import useExploreStore from '../../data/appState/exploreStore';
import { BackArrow, FilterIcon } from '../common/utilComponents';
import toast from '../common/Toast';
import { ExploreTypeSwitch } from '../components/ExploreTypeSwitch';
import { OrgType } from '../../domain/types/Profile';

type ConnectionsState = {
  [orgId: string]: ConnectionStatus;
};

function defaultExploreType(orgType: string | undefined): string {
  orgType = orgType?.toLowerCase();
  if (orgType === 'company') {
    return 'investors';
  }
  if (orgType === 'realestate') {
    return 'investors';
  }
  if (orgType === 'investor') {
    return 'companies';
  }
  return 'companies';
}

function browseTitle(exploreType: string | undefined): JSX.Element {
  exploreType = exploreType?.toLowerCase();
  if (exploreType === 'companies') {
    return (
      <div className="exp_title">
        Explore <span>Companies</span>
      </div>
    );
  }
  if (exploreType === 'realestates') {
    return (
      <div className="exp_title">
        Explore <span>Real Estate</span>
      </div>
    );
  }
  if (exploreType === 'investors') {
    return (
      <div className="exp_title">
        Explore <span>Investors</span>
      </div>
    );
  }
  return <div className="exp_title">Explore</div>;
}

export interface IExploreProps {}
const getStartOfWeek = (date: Date): Date => {
  const startOfWeek = new Date(date);
  const day = startOfWeek.getDay();
  const diff = startOfWeek.getDate() - day; // adjust when day is not Sunday
  startOfWeek.setDate(diff);
  startOfWeek.setHours(0, 0, 0, 0);
  return startOfWeek;
};
const getEndOfWeek = (startOfWeek: Date): Date => {
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 7);
  endOfWeek.setHours(23, 59, 59, 999);
  return endOfWeek;
};

export default function Explore(props: IExploreProps) {
  const navigateTo = useNavigate();

  const { profile, orgConnections } = useProfileStore();
  const [listScrollYPostion, setlistScrollYPostion] = useState(0);
  const [isEnablefilters, setEnableFilters] = useState(false);
  const toggleFilters = () => {
    setEnableFilters(!isEnablefilters);
  };
  const { searchtype, orgId: paramOrgId, orgName: paramOrgName } = useParams();

  const maxRequestsPerWeek: number = 10;
  // Explore Type ...
  var exploreType: string = searchtype
    ? searchtype
    : defaultExploreType(profile?.orgType);

  const [exploreData, setExploreData] = React.useState<ExploreCardData[]>([]);
  const [orgConnectionStates, setOrgConnectionStates] =
    React.useState<ConnectionsState>({});
  const [selectedCard, setSelectedCard] =
    React.useState<ExploreCardData | null>(null);
  const [requestingCard, setRequestingCard] =
    React.useState<ExploreCardData | null>(null);
  const [processState, setProcessState] = useState<ProcessState>(
    ProcessState.Idle,
  );

  const today = new Date();
  const startOfWeek = getStartOfWeek(today);
  const endOfWeek = getEndOfWeek(startOfWeek);

  const [requestMessage, setRequestMessage] = useState<string>('');

  const { selectedOptions, clearSelectedOptions } = useExploreStore();
  const [filteredExploreData, setFilteredExploreData] = React.useState<
    ExploreCardData[]
  >([]);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const closeModal = () => {
    // loadConnections();
    setRequestingCard(null);
  };

  const loadExploreData = (expType) => {
    setProcessState(ProcessState.Loading);
    getExploreData(expType)
      .then((data) => {
        setExploreData(data);

        setProcessState(ProcessState.Success);
      })
      .catch((e) => {
        setProcessState(ProcessState.Failed);
        console.log(e);
      });
  };

  useEffect(() => {
    if (exploreData.length && selectedOptions.length) {
      let filteredData = exploreData.filter((cardData) => {
        //TODO: Refactor this to a Filter function
        // TODO: Refactor this to support AND Condition

        const { orgData } = cardData;
        let orgDataValues = Object.values(orgData);
        let isSelected = false;
        for (let value of orgDataValues) {
          if (typeof value === 'string' || typeof value === 'number') {
            let index = selectedOptions.findIndex(
              (option) => option.value === value,
            );
            isSelected = index > -1;
          }

          // Check if value is string array
          if (Array.isArray(value) && value.length > 0) {
            let index = value.findIndex(
              (v) =>
                selectedOptions.findIndex((option) => option.value === v) > -1,
            );
            isSelected = index > -1;
          }

          if (isSelected) break;
        }
        return isSelected;
      });

      setFilteredExploreData(filteredData);
    } else {
      setFilteredExploreData(exploreData);
    }
  }, [selectedOptions, exploreData]);

  useEffect(() => {
    if (orgConnections?.length) {
      let initState = {} as ConnectionsState;
      let orgConnectionsState = orgConnections.reduce((acc, conn) => {
        let otherOrgId =
          conn.fromOrgId === profile?.organisationId
            ? conn.toOrgId
            : conn.fromOrgId;

        acc[otherOrgId] = conn.connectionStatus;
        return acc;
      }, initState);

      console.log('orgConnections', orgConnections);
      setOrgConnectionStates(orgConnectionsState);
    }
  }, [orgConnections, profile?.organisationId]);

  useEffect(() => {
    loadExploreData(exploreType);
  }, [exploreType]);

  useEffect(() => {
    if (paramOrgId && paramOrgName) {
      let cardData = exploreData.find((card) => card.id === paramOrgId);
      if (cardData) {
        setSelectedCard(cardData);
      } else {
        setSelectedCard(null);
      }
    } else {
      setSelectedCard(null);
    }
  }, [paramOrgId, paramOrgName, exploreData]);

  const handleConnect = async (cardData: ExploreCardData) => {
    setIsRequesting(true);
    if (!profile) return;

    try {
      const {
        displayName: createdByName,
        uid: createdByUid,
        orgType: fromOrgType,
        organisationId: fromOrgId,
        organisationName: fromOrgName,
      } = profile;

      let myConnections = orgConnections.filter((o) => {
        let createdOn = o.createdAt ?? o.createdOn;

        if (createdOn?.toDate) {
          createdOn = createdOn.toDate();
        }
        return (
          o.createdByUid === createdByUid &&
          createdOn > startOfWeek &&
          createdOn <= endOfWeek
        );
      });

      console.log('myConnections', myConnections);
      const maxRequestsReached: boolean =
        myConnections?.length >= maxRequestsPerWeek;

      if (maxRequestsReached) {
        toast.error(
          'Weekly Limit Reached',
          `Our platform allows only ${maxRequestsPerWeek} requests per week`,
        );
        setRequestingCard(null);
        return;
      }

      // let id = profile.organisationId + "_" + cardData.id;

      const { id: toOrgId, orgType: toOrgType, title: toOrgName } = cardData;
      const initState = {
        createdByUid,
        createdByName,
        fromOrgId,
        fromOrgType,
        fromOrgName,
        toOrgId,
        toOrgType,
        toOrgName,
        connectionType: ConnectionType.ORG_ORG,
        type: ConversationType.GROUP,
        [toOrgId]: true,
        [fromOrgId!!]: true,
      };

      const convoMessage: ConvoMessage = {
        text: requestMessage,
        createdById: profile.uid!!,
      };

      const convoParticipant: ConvoParticipant = {
        profileId: profile.id!!,
        profileUid: profile.uid!!,
        profileName: profile.displayName!!,
        profilePic: profile.photoURL ?? '',
      };

      const connectionKey = [fromOrgId, toOrgId].sort().join('_');

      await createConversation(
        initState,
        convoMessage,
        convoParticipant,
        connectionKey,
      );
      setRequestingCard(null);
      //vmc
      setRequestMessage('');
    } catch (error) {
    } finally {
      setIsRequesting(false);
    }
  };

  const gotoConversation = (cardData: ExploreCardData) => {
    let connectionKey = [cardData.id, profile?.organisationId].sort().join('_');
    getConvoByKey(connectionKey, ConnectionType.ORG_ORG).then((convos) => {
      if (convos.length > 0) {
        navigateTo(`/chat/${convos[0].db_ref_id}`);
      }
    });
  };

  const cardStatusAction = (cardData?: ExploreCardData | null): JSX.Element => {
    if (!cardData) return <></>;

    const cardStatus = orgConnectionStates[cardData.id];

    const isNotConnected =
      !cardStatus || cardStatus === ConnectionStatus.NOT_CONNECTED;

    if (isNotConnected) {
      return (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setRequestingCard(cardData);
          }}
        >
          Message
        </Button>
      );
    } else {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return (
        <div
          className="card_status"
          onClick={(e) => {
            e.stopPropagation();
            gotoConversation(cardData);
          }}
        >
          <span>{cardStatus}</span>
        </div>
      );
    }
  };

  const setExpandedCardView = (cardData) => {
    let route = isAdmin
      ? `/admin/explore/${exploreType}/${cardData.id}/${encodeURIComponent(cardData.title)}`
      : `/explore/${exploreType}/${cardData.id}/${encodeURIComponent(cardData.title)}`;
    navigateTo(route);
  };

  const ProcessingListView = () => {
    switch (processState) {
      case ProcessState.Loading:
        return <ShimmerSimpleGallery card imageHeight={300} caption />;
      case ProcessState.Failed:
        return <h1>Failed to load data</h1>;
      case ProcessState.Success:
        return (
          <div className="layout-grid">
            {/* <div > <h3> {filteredExploreData.length } / {exploreData.length}</h3>  </div> */}
            {filteredExploreData.length > 0 ? (
              filteredExploreData.map((cardData) => {
                // un used to check and remove
                // let connectionStatus = orgConnectionStates[cardData.id];
                return (
                  <ExploreCard
                    key={cardData.id}
                    cardData={cardData}
                    cardStatusAction={() => cardStatusAction(cardData)}
                    onCardSelect={(cardData) => {
                      setlistScrollYPostion(window.scrollY);
                      window.scrollTo(0, 0);
                      setSelectedCard(cardData);
                      setExpandedCardView(cardData);
                    }}
                  />
                );
              })
            ) : (
              <h1> No Data Found </h1>
            )}
          </div>
        );
      default:
        return <h1>Loading.. </h1>;
    }
  };

  const ExpandedCardView = (): JSX.Element => {
    let resView = <></>;

    switch (exploreType) {
      case 'companies':
        let company = selectedCard?.orgData as Company;
        resView = (
          <ExpandedCompanyView
            company={company}
            cardStatusAction={() => cardStatusAction(selectedCard)}
          />
        );
        break;

      case 'realestates':
        let re_opp = selectedCard?.orgData as RealEstateOpportunity;
        resView = (
          <ExpandedREOpportunity
            opportunity={re_opp}
            cardStatusAction={() => cardStatusAction(selectedCard)}
          />
        );
        break;

      case 'investors':
        let invFirm = selectedCard?.orgData as InvestmentFirm;
        resView = (
          <ExpandedInvestmentFirmView
            investmentFirm={invFirm}
            cardStatusAction={() => cardStatusAction(selectedCard)}
          ></ExpandedInvestmentFirmView>
        );
        break;

      default:
        resView = <></>;
        break;
    }

    return resView;
  };

  const listWrapClass =
    selectedCard != null ? 'list-wrap single-column' : 'list-wrap';

  let profileOrgType = profile?.orgType as OrgType;
  let isAdmin = profileOrgType === 'ADMIN';

  return (
    <div className="body_layout">
      {/* Filter Header */}

      {!selectedCard ? (
        <>
          <div className="filter_header">
            <div className="filter-wrap">
              <div className="switch-wrap">
                <h1>{browseTitle(exploreType)}</h1>
                {profile?.orgType === 'INVESTOR' || isAdmin ? (
                  <ExploreTypeSwitch
                    exploreType={exploreType}
                    orgType={profile?.orgType}
                    onSwitch={(exploreType: string) => {
                      setSelectedCard(null);
                      let exploreroute = isAdmin
                        ? '/admin/explore/'
                        : '/explore/';
                      let route = exploreroute + exploreType;
                      clearSelectedOptions();
                      navigateTo(route);
                    }}
                  ></ExploreTypeSwitch>
                ) : (
                  <></>
                )}
              </div>
              <div className="ms_filter">
                <FilterIcon onClick={toggleFilters} />
              </div>
              <div>
                <div
                  className={`filtersDiv ${isEnablefilters ? 'enableFilters' : 'disableFilters'}`}
                >
                  {exploreType && exploreData?.length ? (
                    <ExploreFilters
                      eploreCards={exploreData}
                      exploreType={exploreType}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="card_container">
        <div className={listWrapClass}>
          {
            /* Results Cards List */
            ProcessingListView()
          }

          {
            /* Selected Card */

            !selectedCard ? (
              <></>
            ) : (
              <div className="slide-view">
                <div className="close_icons">
                  <BackArrow
                    onClick={() => {
                      setTimeout(() => {
                        if (listScrollYPostion) {
                          window.scrollTo(0, listScrollYPostion + 50);
                        }
                      }, 500);
                      setSelectedCard(null);
                      let route = isAdmin
                        ? `/admin/explore/${exploreType}`
                        : `/explore/${exploreType}`;
                      navigateTo(route);
                    }}
                  />
                  <h3>{selectedCard?.orgData?.name}</h3>
                </div>

                {ExpandedCardView()}
              </div>
            )
          }
        </div>
      </div>
      {/* request confirmation modal */}

      <div>
        <Modal
          className="popup_message"
          open={requestingCard != null}
          title={'Request To Connect With ' + requestingCard?.title}
          onOk={closeModal}
          onCancel={closeModal}
          footer={[
            <Button
              key="back"
              style={{ width: '100%' }}
              type="default"
              onClick={closeModal}
            >
              Cancel
            </Button>,
            <Button
              style={{ width: '100%' }}
              key="submit"
              type="primary"
              loading={isRequesting}
              onClick={() => {
                handleConnect(requestingCard!!);
              }}
            >
              Send
            </Button>,
          ]}
        >
          <p className="paragraph">
            To connect with this company, send them a message request. <br />{' '}
            They will need to accept your request to start a conversation.
          </p>

          <div className="request_text_box">
            <label htmlFor=""> Message</label>
            <textarea
              name=""
              id=""
              placeholder="Enter a message..."
              //VMC
              value={requestMessage}
              onChange={(e) => {
                setRequestMessage(e.target.value);
              }}
            ></textarea>
          </div>
        </Modal>
      </div>
    </div>
  );
}
