import { createBrowserRouter, Navigate } from 'react-router-dom';
// import { PublicLayout } from './components/layouts/PublicLayout';
// import AboutPage from './pages/About';
// import { MainLayout } from './components/layouts/MainLayout';
// import { LoginPage } from './pages/auth/Login';
// import Dashboard from './pages/Dashboard';
// import { UsersPage } from './pages/Users';
// import Chat from './pages/Chat';
// import SignupPage from './pages/auth/Signup';
// import ProfilePage from './pages/Profile';
// import EditProfilePage from './pages/EditProfile';
// import CompanyPage from './pages/Company';
// import EditCompanyPage from './pages/EditCompany';
// import EditInvestorPage from './pages/EditInvestor';
// import InvestorPage from './pages/Investor';
// // import ConnectionsPage from './pages/Connections';
// import CommunitiesPage from './pages/Communities';
// import FileUploadPage from './pages/FileUpload';
// import PrivacyPolicyPage from './pages/PrivacyPolicy';
// import SettingsPage from './pages/Settings';
// import OnBoarding from './pages/auth/OnBoarding';
// import StripePlans from './components/payments/StripePlans';
// import { PaymentStatus } from './components/payments/PaymentStatus';
// import ForgetPwd from './pages/auth/ForgetPwd';
// import DealsPage from './pages/Deals';
// import Network from './pages/Network';
// import RealEstate from './pages/RealEstate';
// import EditRealEstate from './pages/EditRealEstate';
// import Opportunities from './pages/Opportunities';
// import CreateOppurtunity from './components/CreateOpportunity';
// import OpportunityView from './pages/OpportunityView';
// import Notifications from './pages/Notifications';
// import { SelectOrgPage } from './components/auth/selectOrg';
// import { OrgFormPage } from './components/auth/OrgFormPage';
// import { PaymentPage } from './components/auth/Payment';
// import { AuthAction } from './pages/auth/auth-action';
// import SelectOpportunity from './components/chat/Home/SelectOpportunity';
import { PublicLayout } from './v1/presentation/layouts/PublicLayout';
import Login from './v1/presentation/pages/Login';
import { PortalLayout } from './v1/presentation/layouts/PortalLayout';
import Explore from './v1/presentation/pages/Explore';
import Profile from './v1/presentation/pages/Profile';
import { ProfileEdit } from './v1/presentation/pages/ProfileEdit';

import CompanyPageView from './v1/presentation/pages/Company';

import InvestorFirmPage from './v1/presentation/pages/InvestorFirm';
import RealEstatePage from './v1/presentation/pages/RealEstate';
import CompanyEdit from './v1/presentation/pages/CompanyEdit';
import RealEstateEdit from './v1/presentation/pages/RealEstateEdit';
import InvestorFirmEdit from './v1/presentation/pages/InvestorFirmEdit';
import RealEstateOpportunities from './v1/presentation/pages/RealEstateOpportunities';
import RealEstateCreateOpportunity from './v1/presentation/pages/RealEstateCreateOpportunity';
import { MyNetwork } from './v1/presentation/pages/MyNetwork';
import { Chat as ChatPage } from './v1/presentation/pages/Chat';
import { Settings } from './v1/presentation/pages/Settings';
import OnBoarding from './pages/auth/OnBoarding';
import ForgetPwd from './pages/auth/ForgetPwd';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import { AuthAction } from './pages/auth/auth-action';
import { MimicGate } from './v1/presentation/pages/MimicGate';
import { AdminDashboard } from './v1/presentation/pages/Admin/Dashboard';
import { AdminLayout } from './v1/presentation/layouts/AdminLayout';
import { Analytics } from './v1/presentation/pages/Admin/Analytics';
import { Users } from './v1/presentation/pages/Admin/Users';
import { Accounts } from './v1/presentation/pages/Admin/Accounts';
//import RealEstateOpportunityView from './v1/presentation/pages/RealEstateOpportunityView';

const router = createBrowserRouter([
  // {
  //   path: '/',
  //   element: <MainLayout />,
  //   children: [
  //     { index: true, element: <Navigate to="/login" replace /> },
  //     { path: 'dashboard', element: <Dashboard /> },
  //     { path: 'explore', element: <Dashboard /> },
  //     { path: 'explore/:searchtype', element: <Dashboard /> },
  //     { path: 'explore', element: <Dashboard /> },
  //     { path: 'about', element: <AboutPage /> },
  //     { path: 'users', element: <UsersPage /> },
  //     { path: 'chats', element: <Chat /> },
  //     { path: 'chats/:id', element: <Chat /> },
  //     { path: 'opportunity/:id', element: <SelectOpportunity /> },

  //     { path: 'profile', element: <ProfilePage /> },
  //     { path: 'profile/edit', element: <EditProfilePage /> },
  //     { path: 'company', element: <CompanyPage /> },
  //     { path: 'company/:id/view', element: <CompanyPage /> },
  //     { path: 'company/edit', element: <EditCompanyPage /> },
  //     { path: 'investor/edit', element: <EditInvestorPage /> },
  //     { path: 'investor', element: <InvestorPage /> },
  //     { path: 'investor/:id/view', element: <InvestorPage /> },
  //     // { path: 'introductions', element: <ConnectionsPage /> },
  //     // { path: 'introductions/:key', element: <ConnectionsPage /> },
  //     { path: 'communities', element: <CommunitiesPage /> },
  //     { path: 'file', element: <FileUploadPage /> },
  //     { path: 'settings', element: <SettingsPage /> },
  //     { path: 'deals', element: <DealsPage /> },
  //     { path: 'settings/:key', element: <SettingsPage /> },
  //     { path: 'mynetwork', element: <Network /> },
  //     { path: 'realestate', element: <RealEstate /> },
  //     { path: 'realestate/edit', element: <EditRealEstate /> },
  //     { path: 'opportunity/:oid', element: <OpportunityView /> },
  //     { path: 'opportunity/:oid/:view', element: <OpportunityView /> },
  //     { path: 'opportunities', element: <Opportunities /> },
  //     { path: 'createopportunity', element: <CreateOppurtunity /> },
  //     { path: 'updateopportunity/:oid', element: <CreateOppurtunity /> },
  //     { path: 'realestate/:id/view', element: <RealEstate /> },
  //     { path: 'notifications', element: <Notifications /> },
  //     { path: 'notifications/:nid', element: <Notifications /> },
  //   ],
  // },
  // {
  //   path: '/',
  //   element: <PublicLayout />,
  //   children: [
  //    { index: true, element: <LoginPage /> },
  //     { path: 'login', element: <LoginPage /> },
  //     { path: 'plans', element: <StripePlans /> },
  //     { path: 'payment/:status', element: <PaymentStatus /> },
  //     { path: 'signup', element: <SignupPage /> },
  //     { path: 'signup/orgtype', element: <SelectOrgPage /> },
  //     { path: 'signup/orgdetails', element: <OrgFormPage /> },
  //     { path: 'signup/payment', element: <PaymentPage /> },
  //     { path: 'signup/:state', element: <SignupPage /> },
  //     {
  //       path: 'termsofuse',
  //       element: <Navigate to="../assets/tou202309.pdf" />,
  //     },
  //     { path: 'privacypolicy', element: <PrivacyPolicyPage /> },
  //     { path: 'onboarding', element: <OnBoarding /> },
  //     { path: 'forgetpwd', element: <ForgetPwd /> },
  //     { path: 'authaction', element: <AuthAction /> },
  //   ],
  // },

  {
    path: '/',
    element: <PublicLayout />,
    children: [
      { index: true, element: <Navigate to="/login" replace /> },
      { path: 'login', element: <Login /> },
      //TODO: Move these route components to new Folder structure ==> v1/pre...
      { path: 'onboarding', element: <OnBoarding /> },
      { path: 'mimicgate', element: <MimicGate /> },
      { path: 'forgetpwd', element: <ForgetPwd /> },
      { path: 'privacypolicy', element: <PrivacyPolicyPage /> },
      {
        path: 'termsofuse',
        element: <Navigate to="../assets/tou202309.pdf" />,
      },
      { path: 'authaction', element: <AuthAction /> },
    ],
  },
  {
    path: '/',
    element: <PortalLayout />,
    children: [
      { index: true, element: <Login /> },
      { path: 'explore', element: <Explore /> },
      { path: 'explore/:searchtype', element: <Explore /> },

      { path: 'explore/:searchtype/:orgId/:orgName', element: <Explore /> },

      { path: 'profile', element: <Profile /> },
      { path: 'profile/edit', element: <ProfileEdit /> },

      { path: 'company', element: <CompanyPageView /> },
      { path: 'company/:id', element: <CompanyPageView /> },
      { path: 'company/:id/edit', element: <CompanyEdit /> },

      { path: 'investor', element: <InvestorFirmPage /> },
      { path: 'investor/:id', element: <InvestorFirmPage /> },
      { path: 'investor/:id/edit', element: <InvestorFirmEdit /> },

      { path: 'realestate', element: <RealEstatePage /> },
      { path: 'realestate/:id', element: <RealEstatePage /> },
      { path: 'realestate/:id/edit', element: <RealEstateEdit /> },

      { path: 'opportunities', element: <RealEstateOpportunities /> },
      { path: 'createopportunity', element: <RealEstateCreateOpportunity /> },
      {
        path: 'updateopportunity/:oid',
        element: <RealEstateCreateOpportunity />,
      },

      { path: 'mynetwork', element: <MyNetwork /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'chat/:id', element: <ChatPage /> },

      { path: 'settings', element: <Settings /> },
    ],
  },

  // TODO: All the admin routes should be validated with the admin role
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      { index: true, element: <AdminDashboard /> },
      { path: 'dashboard', element: <AdminDashboard /> },
      { path: 'explore', element: <Explore /> },
      { path: 'explore/:searchtype', element: <Explore /> },
      { path: 'profile', element: <Profile /> },
      { path: 'admin', element: <Profile /> },
      { path: 'accounts', element: <Accounts /> },
      { path: 'accounts/:orgtype', element: <Accounts /> },
      { path: 'users', element: <Users /> },

      { path: 'analytics', element: <Analytics /> },
      { path: 'explore/:searchtype/:orgId/:orgName', element: <Explore /> },
    ],
  },

  // {
  //   path: '/v1',
  //   element: <PublicLayout />,
  //   children: [
  //     { index: true, element: <Login /> },
  //     { path: 'login', element: <Login /> },
  //   ],
  // },
  // {
  //   path: '/v1',
  //   element: <PortalLayout />,
  //   children: [
  //     { index: true, element: <Login /> },
  //     { path: 'explore', element: <Explore /> },
  //     { path: 'explore/:searchtype', element: <Explore /> },

  //     { path: 'profile', element: <Profile /> },
  //     { path: 'profile/edit', element: <ProfileEdit /> },

  //     { path: 'company', element: <CompanyPageView /> },
  //     { path: 'company/:id', element: <CompanyPageView /> },
  //     { path: 'company/:id/edit', element: <CompanyEdit /> },

  //     { path: 'investor', element:<InvestorFirmPage /> },
  //     { path: 'investor/:id', element: <InvestorFirmPage /> },
  //     { path: 'investor/:id/edit', element: < InvestorFirmEdit/> },

  //     { path: 'realestate', element: < RealEstatePage/> },
  //     { path: 'realestate/:id', element: < RealEstatePage/> },
  //     { path: 'realestate/:id/edit', element: <RealEstateEdit /> },

  //     { path: 'opportunities', element: < RealEstateOpportunities/> },
  //     { path: 'createopportunity', element: < RealEstateCreateOpportunity/> },
  //     { path: 'updateopportunity/:oid', element: < RealEstateCreateOpportunity/> },

  //     { path: 'mynetwork', element: <MyNetwork /> },
  //     { path: 'chat', element: <ChatPage /> },
  //     { path: 'chat/:id', element: <ChatPage /> },

  //     { path: 'settings', element: <Settings /> },

  //   ],
  // },
]);
export default router;

//http://127.0.0.1:3000/payment/success
