import {
  LeftOutlined,
  RightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Space } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { FileLoader } from './FileLoader';

import { FileIcon, defaultStyles } from 'react-file-icon';
export interface IDocsViewerProps {
  filesUrls: string[];
}

export function DocsViewer({ filesUrls }: IDocsViewerProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [zoomperc, setZoomPerc] = useState(100);

  if (!filesUrls || !filesUrls.length)
    return <div className="no-docs"> No Documents to view</div>;

  let currentFile = filesUrls[currentIndex];

  const handleZoomIn = () => {
    if (zoomperc !== 25) {
      setZoomPerc(zoomperc - 25);
    }
  };

  const handleZoomOut = () => {
    if (zoomperc !== 200) {
      setZoomPerc(zoomperc + 25);
    }
  };

  const gotoPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const gotoNext = () => {
    if (currentIndex < filesUrls.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div className="doc-list-content">
      <FileLoader fileUrl={currentFile} zoompercentage={zoomperc}></FileLoader>
      <div className="doc_footer_control">
        <Space className="zoom-control">
          <ZoomOutOutlined onClick={handleZoomIn} />
          {`${zoomperc}%`}
          <ZoomInOutlined onClick={handleZoomOut} />
        </Space>
        <Space className="doc-control">
          <LeftOutlined onClick={gotoPrev} />
          {currentIndex + 1} / {filesUrls.length}
          <RightOutlined onClick={gotoNext} />
        </Space>
      </div>

      <div className="doc-list">
        <div className="doc-file-icon">
          <FileIcon
            extension={'doc'}
            {...defaultStyles['doc']}
            style={{ height: '100%' }}
          />
        </div>
        {/* <span className="doc-title-txt">Document Title</span>
        <DeleteOutlined /> */}
      </div>

      {/* <Card bordered={false} 
           title={<div className='doc-list' > 
             <div style={{width:"40px"}}> <FileIcon extension={"doc"}  {... defaultStyles["doc"]} style={{ height: "100%" }} />
            </div >  <span className='doc-title-txt'>Document Title</span>  
              
   
            <DeleteOutlined />
            </div>}  
            
        extra={
       <Space>
        <LeftOutlined onClick={gotoPrev} />
        {currentIndex + 1} / {filesUrls.length}
        <RightOutlined onClick={gotoNext}/>
       </Space>} >
     
     <FileLoader fileUrl={currentFile}></FileLoader>
        
      </Card> */}
    </div>
  );
}
