import { notification } from 'antd';

class Toast {
  info(message: string, description: string = ''): void {
    notification.info({ message, description });
  }

  error(message: string, description: string = ''): void {
    notification.error({ message, description });
  }
  success(message: string, description: string = ''): void {
    notification.success({ message, description });
  }

  warning(message: string, description: string = ''): void {
    notification.warning({ message, description });
  }
}

const toast = new Toast();

export default toast;
