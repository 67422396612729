import * as React from 'react';
import useProfileStore from '../../data/appState/profileStore';
import { Avatar, Button, Col, Dropdown, Menu, Modal, Row } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { PROFILE_DEFAULT_IMG } from '../common/constants';
import SocialIcons from '../components/SocialIconsView';
import toast from '../common/Toast';
import { ShimmerSocialPost } from 'react-shimmer-effects';
import { EditIcon } from '../common/utilComponents';

const { confirm } = Modal;

export interface IProfileProps {}

export default function Profile(props: IProfileProps) {
  const { profile } = useProfileStore();
  const navigateTo = useNavigate();

  const { photoURL } = profile || {};

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure delete this Account?',
      icon: <ExclamationCircleFilled />,
      content:
        'Your account deletion request will be sent. An administrator will shortly contact you regarding the process.',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        toast.success('Your request sent successfully', '');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onEditProfile = () => {
    navigateTo('edit');
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={onEditProfile}>
        Edit Profile
      </Menu.Item>
      <Menu.Item key="2" onClick={showDeleteConfirm}>
        Delete Account
      </Menu.Item>
    </Menu>
  );

  if (!profile) return <ShimmerSocialPost type="both" />;
  return (
    <div className="body_layout">
      <div className="fixed_title">
        {' '}
        <h3>Profile</h3>
      </div>
      <div className="sub_header profile_view ">
        <div className="back_icon profile_img ">
          {/* <ArrowLeftOutlined
              onClick={() => navigateTo(-1)}
              style={{ color: '#fff', cursor: 'pointer' }}
            /> */}
          <Avatar
            shape="circle"
            size={122}
            icon={
              // eslint-disable-next-line jsx-a11y/alt-text
              <img src={photoURL ? photoURL : PROFILE_DEFAULT_IMG} />
            }
          />
          <div className="profile-info">
            <h1 className="sub_header_title">
              {' '}
              {profile?.firstName} {profile?.lastName}
            </h1>
            <h4 className="email_view">{profile?.email}</h4>
          </div>
        </div>

        <ul className="sub_header_actions hide_btn">
          <li className="btn_delete">
            <Button type="default" onClick={showDeleteConfirm}>
              Delete Account
            </Button>
          </li>
          <li onClick={onEditProfile}>
            <Button type="primary">Edit Profile</Button>
          </li>
        </ul>
        <Dropdown
          className="show_mobile"
          overlay={menu}
          arrow
          trigger={['click']}
        >
          <EditIcon />
        </Dropdown>
      </div>

      <Row>
        <Col span={24}>
          <div className="wvp-p-flex-d">
            <h3 className="Secondary-textcolor">
              Personal <span>Information</span>
            </h3>
            <div className="grid-3">
              <div className="label-group">
                <label htmlFor="">Name</label>
                <p>
                  {profile?.firstName} {profile?.lastName}{' '}
                </p>
              </div>
              <div className="label-group">
                <label htmlFor="">Display Name (Alias)</label>
                <p>{profile?.displayName}</p>
              </div>
              <div className="label-group">
                <label htmlFor="">Job Title</label>
                <p>{profile?.title}</p>
              </div>
              <div className="label-group">
                <label htmlFor="">Phone Number</label>
                <p>{profile.phoneNumber}</p>
              </div>
              <div className="label-group">
                <label htmlFor="">Email</label>
                <p>
                  <Link to={`mailto:${profile?.email?.toLowerCase()}`}>
                    {profile?.email}
                  </Link>
                </p>
              </div>
              <div className="label-group">
                {profile?.city ? (
                  <>
                    <label>City</label>
                    <p>{profile?.city}</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="label-group">
                {profile?.state ? (
                  <>
                    <label>State</label>
                    <p>{profile?.state}</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="label-group">
                {profile?.country ? (
                  <>
                    <label>Country</label>
                    <p>{profile?.country}</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <h3 className="Secondary-textcolor">
              Social <span>Links</span>{' '}
            </h3>
            {profile?.socialLinks?.length !== 0 ? (
              <SocialIcons iconsList={profile?.socialLinks!!}></SocialIcons>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
