import * as React from 'react';
import { OrgType } from '../../domain/types/Profile';

export interface IExploreTypeSwitchProps {
  exploreType: string | undefined;
  onSwitch: (newType: string) => void; // Define a proper function type
  orgType?: OrgType;
}

export function ExploreTypeSwitch({
  exploreType,
  onSwitch,
  orgType,
}: IExploreTypeSwitchProps) {
  let exploreTypes: { id: string; label: string }[] = [];

  if (orgType === 'INVESTOR') {
    exploreTypes = [
      { id: 'companies', label: 'Companies' },
      { id: 'realestates', label: 'Real Estate' },
    ];
  }

  if (orgType === 'ADMIN') {
    exploreTypes = [
      { id: 'investors', label: 'Investors' },
      { id: 'companies', label: 'Companies' },
      { id: 'realestates', label: 'Real Estate' },
    ];
  }

  return (
    <div className="filter_space">
      <div className="flight-types">
        {exploreTypes.map((exploreTypeval, index) => (
          <div
            key={index}
            className={`switch_label ${exploreType === exploreTypeval.id ? 'checked' : ''}`}
            onClick={() => {
              if (onSwitch) onSwitch(exploreTypeval.id);
            }}
          >
            {exploreTypeval.label}
          </div>
        ))}
      </div>
    </div>
  );
}
