import * as React from 'react';
import { useEffect } from 'react';

import {
  getConnectionsData,
  getConnectionsNewUsersData,
  tablesConfig,
} from '../../../data/services/analytics/analyticsService';
import { ReportCard } from './ReportCard';
export interface IAnalyticsProps {}

export function Analytics(props: IAnalyticsProps) {
  const loadConnections = async () => {
    try {
      const response = await getConnectionsData();
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  const loadConnectionsNewUsers = async () => {
    try {
      const response = await getConnectionsNewUsersData();
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadConnections();
    loadConnectionsNewUsers();
  }, []);

  return (
    <div className="analytic_page body_layout">
      <h1 className="analytic_title"> Analytics </h1>

      <div>
        <div className="admin-grid-2">
          {tablesConfig.map((tableConfig) => {
            return (
              <div className="grid_section">
                <h4>{tableConfig.title}</h4>
                <ReportCard tableConfig={tableConfig} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
