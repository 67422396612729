import * as React from 'react';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import { FOOTER_LOGO, WVP_LOGO_NEW } from '../common/asset_paths';
import { getMenuLinkText } from '../common/constants';

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Space, Dropdown } from 'antd';
import useProfileStore from '../../data/appState/profileStore';
import { OrgType, Profile } from '../../domain/types/Profile';
import { signOutUser } from '../../data/services/auth/authService';
import { useLoadSiteData } from '../../data/services/common/siteDataService';
import { getProfileConversationsByProfileId } from '../../data/services/profile/profileService';
import {
  AccountIcon,
  ExploreIcon,
  InstagramIcon,
  MessagesIcon,
  NetworkIcon,
  SignoutIcon,
  UserIcon,
  XIcon,
} from '../common/utilComponents';
import {
  saveFCMTokenToUser,
  UpdateUserPushMessageToken,
} from '../../../api/features/profile';
declare global {
  interface Window {
    MSStream?: any;
  }
  interface Window {
    opera?: any;
  }
  interface Window {
    gtag?: any;
  }
}
export interface IAdminLayoutProps {}

export function AdminLayout(props: IAdminLayoutProps) {
  const {
    isAuthenticated,
    profile,
    orgConnections,
    unReadConvoIds,
    setUnReadConvoIds,
  } = useProfileStore();

  const location = useLocation();
  const navigateTo = useNavigate();

  // const lsIsWebView = localStorage.getItem('iswebview');

  //const isWebView = lsIsWebView?.toString() === 'true';

  const [activemenu, setActivemenu] = useState('explore');
  const [convoState, setConvoState] = useState<any>({});
  const [mobileHeaderTitle, setMobileHeaderTitle] = useState('');

  const bottomNavHidden = /^\/chat\/[a-zA-Z0-9]+$/.test(location.pathname);

  let profileOrgType = profile?.orgType as OrgType;
  let isAdmin = profileOrgType === 'ADMIN';

  //const [isNotified, setIsNotified] = useState(false);

  useEffect(() => {
    setMobileHeaderTitle(getMenuLinkText(location.pathname));
    window.scrollTo(0, 0);
  }, [location]);

  const handleLinkClick = (path) => {
    // setActiveLink(path);
  };

  const { loaded } = useLoadSiteData();

  useEffect(() => {
    if (isAuthenticated) {
      if (!profile) {
        navigateTo('/login');
      } else {
        if (window.gtag && profile?.uid) {
          window.gtag('js', new Date());
          window.gtag('config', 'G-90VEK60GXP', { user_id: profile.uid });
        }
      }
    }
  }, [isAuthenticated, profile, navigateTo]);

  const setFCMToken = async (uid: string) => {
    let localStorageToken = window['fcmtokem'];

    if (localStorageToken) {
      //alert(localStorageToken);
      await saveFCMTokenToUser(uid, localStorageToken);
    } else {
      try {
        await UpdateUserPushMessageToken(uid);
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (profile?.db_ref_id) {
      try {
        getProfileConversationsByProfileId(profile?.db_ref_id).then((s) => {
          setConvoState(s);
        });
      } catch (error) {
        console.warn(error);
      }
    }

    if (profile?.uid) {
      setFCMToken(profile?.uid);
    }
  }, [profile?.db_ref_id, profile?.uid]);

  useEffect(() => {
    let unreadConvos: string[] = [];

    if (convoState?.length && orgConnections?.length)
      for (const connection of orgConnections) {
        const { lastMessage } = connection;

        if (lastMessage?.createdById !== profile?.uid) {
          let thisconvoState = convoState.find(
            (c) => c.db_ref_id === connection.db_ref_id,
          );

          // if (!lastMessage) {
          //   unreadConvos.push(connection.db_ref_id);
          //   console.log('unreadConvo', connection);
          //   continue;
          // }
          if (
            lastMessage &&
            thisconvoState &&
            thisconvoState.lastVisited < lastMessage?.createdOn
          ) {
            console.log('unreadConvo', connection);
            unreadConvos.push(connection.db_ref_id);
          }
        }
      }

    // if (unReadConvoIds < unreadConvos && unreadConvos.length > 0) {
    // const messageCount = unreadConvos.length;
    // const messageText = messageCount === 1 ? 'new message' : 'new messages';
    // toast.info(`You have ${messageCount} ${messageText}`, '');
    // }
    //to do reenable
    setUnReadConvoIds(unreadConvos);
  }, [convoState, orgConnections, profile?.uid, setUnReadConvoIds]);

  const { displayName, email, orgType, photoURL } = profile || ({} as Profile);

  const navigateToOrg = () => {
    // if (profile?.role === 'Member') {
    //   let path = `/${orgType?.toLowerCase()}/${profile.organisationId}/view`;
    //   navigateTo(path);
    //   // setActiveLink(path);
    // } else {
    navigateTo(`${orgType?.toLowerCase()}`);
    // setActiveLink(`${orgType?.toLowerCase()}`);
    // }
  };

  const profileIconMenu = (
    <div className="profile-card">
      <div
        onClick={(e) => {
          navigateTo('profile');
        }}
      >
        <div className="profile-header" key={'profile'}>
          {!photoURL ? (
            <Avatar icon={<UserOutlined />} className="navbar-img" />
          ) : (
            <Avatar
              src={<img src={photoURL} alt="avatar" />}
              className="profile-picture"
            />
          )}
          <div className="profile-info">
            <h2> {displayName}</h2>
            <p>{email}</p>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="profile-menu">
        <div className="menu-item" key={'orgnization'}>
          <div onClick={navigateToOrg}>
            <UserIcon className="icon" />
            <span>My {orgType?.toLowerCase()} profile</span>
          </div>
        </div>

        <div className="menu-item" key={'logout'}>
          <div onClick={signOutUser}>
            <SignoutIcon className="icon" />
            <span>Sign Out</span>
          </div>
        </div>
      </div>
    </div>
  );

  const unreadCount = unReadConvoIds.length ? (
    <span className="badge_count">{unReadConvoIds.length}</span>
  ) : null;

  if (profile && !isAdmin) {
    return (
      <div>
        <h1> Access Restricted.</h1>
      </div>
    );
  }

  if (!profile) return <div className="portallayout admin-view"></div>;

  return (
    <div className="portallayout admin-view">
      <div className="navbar">
        <nav className="navbar-header">
          <div className="navbar-container">
            <div className="mobile_header_title">
              <h1>{mobileHeaderTitle}</h1>
            </div>
            <Link to={'/admin/explore'} className="navbar-logo">
              <img src={WVP_LOGO_NEW} alt="Logo" />
            </Link>

            <ul className="navbar-menu">
              {!isAdmin ? (
                <></>
              ) : (
                <li className="navbar-item">
                  <Link
                    className={`navbar-link ${location.pathname === '/admin' ? 'active' : ''}`}
                    to={'/admin'}
                    onClick={() => handleLinkClick('/admin')}
                  >
                    Admin
                  </Link>
                </li>
              )}

              <li className="navbar-item">
                <Link
                  className={`navbar-link ${location.pathname.indexOf('explore') !== -1 ? 'active' : ''}`}
                  to={'/admin/explore'}
                  onClick={() => handleLinkClick('/admin/explore')}
                >
                  Explore
                </Link>
              </li>

              <li className="navbar-item">
                <Link
                  className={`navbar-link ${location.pathname.indexOf('analytics') !== -1 ? 'active' : ''}`}
                  to={'/admin/analytics'}
                  onClick={() => handleLinkClick('/admin/analytics')}
                >
                  Analytics
                </Link>
              </li>

              <li className="navbar-item">
                <Dropdown
                  overlay={profileIconMenu}
                  arrow={{ pointAtCenter: false }}
                  placement="bottom"
                >
                  <Space>
                    {!photoURL ? (
                      <Avatar
                        size={40}
                        icon={<UserOutlined />}
                        className="navbar-img"
                      />
                    ) : (
                      <Avatar
                        size={40}
                        src={<img src={photoURL} alt="avatar" />}
                        className="navbar-img"
                      />
                    )}
                  </Space>
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      {isAdmin && loaded ? <Outlet></Outlet> : <></>}

      <footer className="footer">
        <div className="footer-left">
          <div className="footer-logo">
            <img src={FOOTER_LOGO} alt="" />
          </div>
          <div className="footer-links">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.wealthvp.com/privacy-statement"
            >
              Privacy Policy
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://cdn.prod.website-files.com/6639818d2d30ae6a87243cce/665758f0e4e15df709a1fb76_WealthVP%20Terms%20of%20Use.pdf"
            >
              Terms of Use
            </a>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-menu">
            <a href="#!">Companies</a>
            <a href="#!">Investors</a>
            <a href="#!">Real Estate</a>
          </div>
          <div className="footer-contact">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://share.hsforms.com/1DlJ6huT7QYuyIc7mrKq2Vw4303u"
            >
              {' '}
              Contact Us
            </a>
            <div className="footer_social_link">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/wealthvp/"
                className="linkedin"
              >
                <InstagramIcon />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://x.com/WealthVP"
                className="x"
              >
                <XIcon />
              </a>
            </div>
          </div>
        </div>
      </footer>

      {bottomNavHidden ? (
        ' '
      ) : (
        <>
          <div className="m-menu">
            <footer className="m-menu_item">
              <li
                className={activemenu === 'explore' ? 'active' : ''}
                onClick={() => {
                  navigateTo('/admin/explore');
                  setActivemenu('explore');
                }}
              >
                <ExploreIcon className="meun_icon" />
                <div className="menu_title">Explore</div>
              </li>
              <li
                className={activemenu === 'mynetwork' ? 'active' : ''}
                onClick={() => {
                  navigateTo('/mynetwork');
                  setActivemenu('mynetwork');
                }}
              >
                <NetworkIcon className="meun_icon" />
                <div className="menu_title">Network</div>
              </li>
              <li
                className={activemenu === 'chats' ? 'active' : ''}
                onClick={() => {
                  navigateTo('/chat');
                  setActivemenu('chats');
                }}
              >
                {' '}
                {unreadCount}
                <MessagesIcon className="meun_icon" />
                <div className="menu_title">Messages</div>
              </li>
              <li
                className={activemenu === 'profile' ? 'active' : ''}
                onClick={() => {
                  navigateTo('/profile');
                  setActivemenu('profile');
                }}
              >
                <AccountIcon className="meun_icon" />
                <div className="menu_title">Account</div>
              </li>
            </footer>
          </div>
        </>
      )}
    </div>
  );
}
