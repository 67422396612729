import { Avatar, Button, Form, Input, Select } from 'antd';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InvestmentFirm, TeamMember } from '../../domain/types/Investor';
import { useEffect, useState } from 'react';
import {
  getInvestmentFirmById,
  updateInvestorFirm,
} from '../../data/services/investor/investorService';
import { investorSiteProperties } from '../../data/services/common/siteDataService';
import { DEFAULT_FIRM_IMG, YES_NO_DDL_LIST } from '../common/constants';
import FileUploader from '../common/FileUploader';
import { SocialLinksEditor } from '../components/social-links-form-item';
import toast from '../common/Toast';
import { BackArrow } from '../common/utilComponents';
import { TeamMemberForm } from '../components/TeamMemberForm';
import { TeamMembersList } from '../components/TeamMembersList';

export interface IInvestorFirmEditPageProps {}

export default function InvestorFirmEditPage(
  props: IInvestorFirmEditPageProps,
) {
  const [form] = Form.useForm<InvestmentFirm>();
  const [investorFirm, setInvestorFirm] = useState<InvestmentFirm | any>(null);
  const [industries] = useState<string[]>(investorFirm?.industries ?? []);
  const [pictureUrl, setPictureUrl] = useState<any>(
    investorFirm?.logoUri ?? '',
  );
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>(
    investorFirm?.teamMembers ?? [],
  );
  const [socialLinks, setSocialLinks] = useState<string[]>(
    investorFirm?.socialLinks ?? [],
  );

  const { id: firmId } = useParams();
  // const { profile } = useProfileStore();
  const navigateTo = useNavigate();

  // const {
  //     assets_under_management,
  //     investor_type,
  //     industry_type } = InvestorSiteProperties ?? {};

  const { assets_under_management, investor_type, industry_type } =
    investorSiteProperties ?? {};

  useEffect(() => {
    if (firmId)
      getInvestmentFirmById(firmId)
        .then((data: InvestmentFirm) => {
          if (data.websiteUri === 'https://null') {
            data.websiteUri = '';
          }
          form.setFieldsValue(data);
          setInvestorFirm(data);
          if (data.teamMembers?.length) {
            setTeamMembers(data.teamMembers);
          }
          if (data.socialLinks?.length) {
            setSocialLinks(data.socialLinks);
          }
          if (data.logoUri?.length) {
            setPictureUrl(data.logoUri);
          }
          // console.log(data)
        })
        .catch((err) => {
          console.log(err);
        });
  }, [firmId, form]);

  const onFinish = async (values: InvestmentFirm) => {
    setIsUpdating(true);
    if (!pictureUrl) {
      toast.error('Please upload profile picture', '');
      setIsUpdating(false);

      return;
    }
    values.teamMembers = teamMembers;
    values['socialLinks'] = socialLinks.length !== 0 ? [...socialLinks] : [];
    values['logoUri'] = pictureUrl ? pictureUrl : null;
    // if (
    //   values.websiteUri &&
    //   (values.websiteUri?.indexOf('http') === -1 ||
    //     values.websiteUri.indexOf('www.') === 0)
    // ) {
    //   values.websiteUri = 'https://' + values.websiteUri;
    // }
    var updatedFirm = { ...investorFirm, ...values };

    updateInvestorFirm(updatedFirm)
      .then((data) => {
        toast.success('Your profile is updated', '');
        setIsUpdating(false);
        // navigateTo('/investor/' + investorFirm.id);
        navigateTo(-1);
      })
      .catch((err) => {
        console.log(err);
        toast.warning('Failed to save', '');
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="body_layout">
      <Form
        initialValues={{ ...investorFirm }}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="sub_header">
          <div className="back_icon">
            <BackArrow onClick={() => navigateTo(-1)} />
            <h3>Edit Investor Profile</h3>
          </div>

          <div className="sub_header_actions">
            <>
              {/* <FileUploader
                onUploadComplete={(e) => {
                  setPictureUrl(e?.url);
                }}
              ></FileUploader> */}
            </>
            {/* {pictureUrl ? (
              <div>
                <Popover
                  placement="bottom"
                  content={
                    <div>
                      <Space direction="vertical">
                        <div
                          className="color_white hand"
                          onClick={() => {
                            setPictureUrl(null);
                            setOpenLogoMenu(false);
                          }}
                        >
                          Change
                        </div>
                        <div
                          className="color_white hand"
                          onClick={() => {
                            setPictureUrl(null);
                            setOpenLogoMenu(false);
                          }}
                        >
                          Remove
                        </div>
                      </Space>
                    </div>
                  }
                  open={openLogoMenu}
                  onOpenChange={(newOpen: boolean) => {
                    setOpenLogoMenu(newOpen);
                  }}
                >
                  <Avatar
                    shape="circle"
                    icon={
                      <img
                        alt=""
                        src={pictureUrl ? pictureUrl : DEFAULT_FIRM_IMG}
                      />
                    }
                  />
                </Popover>
              </div> 
            // ) : (*/}
            <Avatar
              shape="square"
              icon={
                <img alt="" src={pictureUrl ? pictureUrl : DEFAULT_FIRM_IMG} />
              }
            />
            <div className="upload-container">
              <label className="upload-box">
                <FileUploader
                  onUploadComplete={(e) => {
                    setPictureUrl(e?.url);
                  }}
                ></FileUploader>
              </label>
            </div>
          </div>
        </div>
        <div className="wvp-p-flex-d">
          <h3 className="Secondary-textcolor">Investor</h3>
          <div className="grid-3 ">
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'Please input company name',
                },
              ]}
            >
              <Input placeholder="company name" />
            </Form.Item>
            <Form.Item
              name="investorType"
              label="Investor Type"
              rules={[
                {
                  required: true,
                  message: 'Please select investor type',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                options={investor_type?.options ?? []}
              />
            </Form.Item>

            <Form.Item
              name="assetsUnderManagement"
              label="Assets Under Management"
              rules={[
                {
                  required: true,
                  message: 'Please enter Assets Under Management',
                },
              ]}
            >
              <Select
                //  onChange={(e) => handleChange(e)}
                options={assets_under_management?.options ?? []}
              />
            </Form.Item>
            <Form.Item
              name="hideFromCompanies"
              label="Hide profile from companies"
              rules={[
                {
                  required: true,
                  message: 'Please select assets Under Management',
                },
              ]}
            >
              <Select style={{ width: '100%' }} options={YES_NO_DDL_LIST} />
            </Form.Item>

            <Form.Item
              name="interestedInSyndicateDeals"
              label="Interested in Syndicate Deals?"
              rules={[
                {
                  required: true,
                  message: 'Please enter Type',
                },
              ]}
            >
              <Select options={YES_NO_DDL_LIST} />
            </Form.Item>

            <Form.Item
              name="companiesFunded"
              label="Companies Funded"
              rules={[
                {
                  required: false,
                  message: 'Please enter your Companies Funded!',
                },
              ]}
            >
              <Input type="number" placeholder="Companies Funded" />
            </Form.Item>

            <Form.Item
              name="activeInvestments"
              label="Active Investments"
              rules={[
                {
                  required: false,
                  message: 'Please enter your Active Investments!',
                },
              ]}
            >
              <Input type="number" placeholder="Active Investments" />
            </Form.Item>

            <Form.Item
              name="exits"
              label="Exits"
              rules={[{ required: false, message: 'Please enter your Exits!' }]}
            >
              <Input type="number" placeholder="Exits" />
            </Form.Item>

            <Form.Item
              name="ventureFunds"
              label="Venture Funds"
              rules={[
                {
                  required: false,
                  message: 'Please enter your Venture Funds!',
                },
              ]}
            >
              <Input prefix="$" type="number" placeholder="Venture Funds" />
            </Form.Item>
          </div>

          <h3 className="Secondary-textcolor">Industries</h3>

          <div>
            <Form.Item
              name="industries"
              label="Industries"
              rules={[
                {
                  required: true,
                  message: 'Please select industries',
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select"
                defaultValue={industries?.length ? [...industries] : []}
                options={industry_type?.options ?? []}
              />
            </Form.Item>
          </div>

          <h3 className="Secondary-textcolor">Firm</h3>
          <div className="grid-3 ">
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: 'Please enter valid City',
                },
              ]}
            >
              <Input placeholder="Enter City" name="city" />
            </Form.Item>

            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message: 'Please enter valid state',
                },
              ]}
            >
              <Input placeholder="Enter State" />
            </Form.Item>

            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: 'Please enter  valid country',
                },
              ]}
            >
              <Input placeholder="Enter country" />
            </Form.Item>

            <Form.Item
              name="websiteUri"
              label="Website"
              rules={[
                {
                  required: true,
                  message: 'Please enter Website',
                },
                { type: 'url' },
                { type: 'string', min: 6 },
              ]}
            >
              <Input placeholder="Enter website" />
            </Form.Item>
          </div>
          <h3 className="Secondary-textcolor">About</h3>
          <Form.Item
            name="about"
            rules={[{ required: true, message: 'Please input Intro' }]}
          >
            <Input.TextArea rows={10} />
          </Form.Item>

          <h3 className="Secondary-textcolor">Team</h3>
          <TeamMemberForm
            onAddMember={(teamMember) => {
              setTeamMembers([...teamMembers, teamMember]);
            }}
          ></TeamMemberForm>

          <div>
            <TeamMembersList
              teamMembers={teamMembers}
              onDeleteMember={(index) => {
                teamMembers.splice(index, 1);
                setTeamMembers?.([...teamMembers]);
              }}
            ></TeamMembersList>
          </div>

          <h3 className="Secondary-textcolor">
            Social <span>Links</span>{' '}
          </h3>
          <SocialLinksEditor
            value={socialLinks}
            onChange={(v) => {
              setSocialLinks(v);
            }}
          />
          <div className="actions_btn">
            <Form.Item shouldUpdate>
              {({ getFieldsError }) => {
                // Check if there are any errors in the form fields
                // const hasErrors = getFieldsError().some(
                //   (field) => field.errors.length > 0,
                // );

                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    // type="submit"
                    className="bg-primary"
                    // disabled={saving || hasErrors}
                    loading={isUpdating}
                  >
                    Save
                  </Button>
                );
              }}
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
