import React, { useEffect } from 'react';
import EmailLogin from '../components/EmailLogin';
import useProfileStore from '../../data/appState/profileStore';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { WVP_LOGO_NEW } from '../common/asset_paths';
import { getUrlParameterValue } from '../common/utils';
import { OrgType } from '../../domain/types/Profile';

const Login: React.FC = () => {
  const { profile } = useProfileStore();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/login') {
      if (profile) {
        let redirectPath = decodeURI(getUrlParameterValue('rediectPath'));
        if (redirectPath) {
          navigate(redirectPath);
        } else {
          let profileOrgType = profile?.orgType as OrgType;
          let isAdmin = profileOrgType === 'ADMIN';
          if (isAdmin) {
            navigate('/admin/explore');
          } else {
            navigate('/explore');
          }
        }
      }
    }
  }, [location.pathname, profile, navigate]);

  const LoginHeader = () => {
    return (
      <div className="header_text">
        <img className="logo_asset" src={WVP_LOGO_NEW} alt="WealthVP" />
        <h2>Login</h2>
      </div>
    );
  };

  const LoginFooter = () => {
    return (
      <>
        <div className="terms">
          By continuing, you agree to our <br />
          <Link to="../assets/tou202309.pdf" target="_blank">
            Terms of Use and Acceptable Use Policy
          </Link>
        </div>
      </>
    );
  };

  return (
    <div className="login_page">
      <LoginHeader />

      <EmailLogin />

      <LoginFooter />
    </div>
  );
};

export default Login;
