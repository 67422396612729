//Settings

import * as React from 'react';
import useProfileStore from '../../data/appState/profileStore';
import { Button } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { getProfilesByOrgId } from '../../data/services/profile/profileService';
import { CloseIcon } from '../common/utilComponents';
import { InviteUser } from '../components/InviteUser';
import { getCompanyMembers } from '../../data/services/company/companyService';
import { CopyOutlined } from '@ant-design/icons';
import toast from '../common/Toast';

export interface ISettingsProps {}

type OrgMember = {
  name: string;
  email: string;
  status: string;
  role: string;
  onboardLink: string;
};

const copyToClipboard = (value) => {
  navigator.clipboard.writeText(value).then(
    () => {
      // openNotification(`Copied to clipboard`, notificationTypes.success);
      toast.success('Copied to clipboard', '');
    },
    () => {
      console.error('Failed to copy');
      // openNotification(`Failed to copy `, notificationTypes.error);
      toast.error('Failed to copy ', '');
    },
  );
};

export function Settings(props: ISettingsProps) {
  const { profile } = useProfileStore();

  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const [orgMembers, setOrgMembers] = useState<OrgMember[]>([]);

  const loadOrgMembers = useCallback(async () => {
    if (profile?.organisationId) {
      let _orgMembers: OrgMember[] = [];

      let profiles = await getProfilesByOrgId(profile?.organisationId);
      if (profiles?.length) {
        _orgMembers = profiles.map((p) => {
          return {
            name: p.displayName,
            email: p.email,
            status: p.userStatus,
            role: p.role,
          } as OrgMember;
        });
      }
      let companyMembers = await getCompanyMembers(profile?.organisationId);

      if (companyMembers?.length) {
        _orgMembers = [
          ..._orgMembers,
          ...companyMembers.map((m) => {
            let member = {
              name: m.name,
              email: m.email,
              status: m.status,
              role: m.role,
            } as OrgMember;
            if (m.status === 'INVITED') {
              member.onboardLink = `${window.location.origin}/onboarding?token=${m.orgId}_${m.db_ref_id}`;
            }

            return member;
          }),
        ];
      }
      setOrgMembers(_orgMembers);
    }
  }, [profile?.organisationId]);

  useEffect(() => {
    loadOrgMembers();
  }, [loadOrgMembers]);

  return (
    <div className="body_layout">
      <div className="setting_header">
        <h3 className="exp_title ms-hide">Settings</h3>
        <div className="web_add_btn">
          {profile?.orgType !== 'INVESTOR' ? (
            <Button type="default" onClick={openPopup}>
              Add User
            </Button>
          ) : (
            <></>
          )}
        </div>

        {!isPopupOpen ? (
          <></>
        ) : (
          <div className="popup-overlay">
            <div className="popup-content">
              <div className="popup-header">
                <h2>Add User</h2>
                <CloseIcon onClick={closePopup} />
              </div>

              <InviteUser
                onAddComplete={(isAdded) => {
                  closePopup();
                  if (isAdded) {
                    loadOrgMembers();
                    //  window.location.reload();
                  }
                }}
              ></InviteUser>
            </div>
          </div>
        )}
      </div>

      <div style={{ marginTop: '1rem' }}>
        <div className="table-container slim_scrollbar ">
          <table>
            <thead>
              <tr>
                <th>USER</th>
                <th>ROLE</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {orgMembers.map((member, index: number) => {
                return (
                  <tr key={`${member.email}-${index}`}>
                    <td>{member.name}</td>
                    <td>{member.role}</td>
                    <td>
                      {member.status}

                      {member.onboardLink ? (
                        <Button
                          className="copy_btn"
                          type="default"
                          onClick={() => copyToClipboard(member.onboardLink)}
                        >
                          <CopyOutlined /> Copy link
                        </Button>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="ms_add_btn">
        {profile?.orgType !== 'INVESTOR' ? (
          <Button type="default" onClick={openPopup}>
            Add User
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export interface IOrgUsersProps {
  orgId?: string;
}
