import { Company } from '../../../domain/types/Company';
import { RealEstateOpportunity } from '../../../domain/types/RealEstateOpportunity';
// import { REAL_ESTATE_IMG } from '../../../presentation/common/constants';
// import { DEFAULT_FIRM_IMG } from '../../../presentation/common/constants';
import { ExploreCardData } from './ExploreCardData';
import { convertNumbertoCurrency } from '../../../presentation/common/utils';
import {
  SiteDataOption,
  companySiteProperties,
  investorSiteProperties,
  realestateSiteProperties,
} from '../common/siteDataService';
import { InvestmentFirm } from '../../../domain/types/Investor';
import { Timestamp } from 'firebase/firestore';

import dayjs from 'dayjs';

export const getlabelsByValues = (
  selectedValues?: string[],
  optionsList?: SiteDataOption[],
): string[] => {
  if (!selectedValues?.length || !optionsList?.length) return [];
  try {
    return optionsList
      .filter((o) => selectedValues.indexOf(o.value) !== -1)
      .map((o) => o.label);
  } catch (error) {
    return [];
  }
};
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export function convertREOpportunityToExploreCardData(
  oppo: RealEstateOpportunity,
): ExploreCardData {
  const {
    investment_type,
    property_sector,
    anticipated_term,
    return_structure,
    investment_strategy,
    investment_structure,
    tax_advantage,
  } = realestateSiteProperties;

  return {
    id: oppo.id || (oppo.db_ref_id ?? ''),
    title: oppo.name,
    imageUrl:
      oppo.pictureUrls && oppo.pictureUrls.length !== 0
        ? oppo.pictureUrls[0]
        : '',
    about: oppo.description,
    orgType: 'REALESTATE',
    orgData: oppo,
    detailsList: [
      {
        label: 'Deal Size',
        value: convertNumbertoCurrency(oppo?.totalDealSize),
      },

      {
        label: 'Min. Investment',
        value: convertNumbertoCurrency(oppo?.minimumInvestment),
      },
      {
        label: 'Inv. Type',
        value: oppo?.investmentType
          ? getlabelsByValues([oppo.investmentType], investment_type.options)
          : '',
      },
      {
        label: 'Sectors',
        value: getlabelsByValues(oppo?.sectors, property_sector.options),
      },
      {
        label: 'Term',
        value: oppo?.anticipatedTerm
          ? getlabelsByValues([oppo?.anticipatedTerm], anticipated_term.options)
          : '',
      },
      {
        label: 'Return Structure',
        value: oppo?.returnStructure
          ? getlabelsByValues([oppo?.returnStructure], return_structure.options)
          : '',
      },
      {
        label: 'Strategy',
        value: oppo?.investmentStrategy
          ? getlabelsByValues(
              [oppo?.investmentStrategy],
              investment_strategy.options,
            )
          : '',
      },
      {
        label: 'Inv. Structure',
        value: oppo?.investmentStructure
          ? getlabelsByValues(
              [oppo?.investmentStructure],
              investment_structure.options,
            )
          : '',
      },
      {
        label: 'Tax Adv.',
        value: oppo?.taxAdvantage
          ? getlabelsByValues([oppo?.taxAdvantage], tax_advantage.options)
          : '',
      },
      {
        label: 'Location',
        value: [oppo?.city, oppo?.state, oppo?.country],
      },
    ],
  };
}

export function convertCompanyToExploreCardData(
  company: Company,
): ExploreCardData {
  const { company_structure, industry_type, pre_funding_valuation } =
    companySiteProperties ?? {};

  return {
    id: company.id,
    title: company.name,
    imageUrl: company.logoUri ? company.logoUri : '',
    about: company.about,
    orgType: 'COMPANY',
    orgData: company,
    detailsList: [
      {
        label: 'Seeking',
        value: convertNumbertoCurrency(company?.seekingAmount),
      },
      {
        label: 'Structure',
        value: getlabelsByValues(
          company?.structures,
          company_structure?.options,
        ),
      },
      {
        label: 'Min. Investment',
        value: convertNumbertoCurrency(company?.minimumInvestment),
      },
      //getlabelsByValues([oppo.investmentType], investment_type.options)
      //convertNumbertoCurrency(company?.preFundingValuation)
      {
        label: 'Pre-Money',
        value: company?.preFundingValuation
          ? getlabelsByValues(
              [company?.preFundingValuation],
              pre_funding_valuation.options,
            )
          : '',
      },
      {
        label: 'Industries',
        value: getlabelsByValues(company?.industries, industry_type?.options),
      },
    ],
  };
}

export function convertInvestmentFirmToCardData(
  invFirm: InvestmentFirm,
): ExploreCardData {
  const { assets_under_management, investor_type, industry_type } =
    investorSiteProperties;

  return {
    id: invFirm.id,
    title: invFirm.name,
    imageUrl: invFirm.logoUri ? invFirm.logoUri : '',
    about: invFirm.about,
    orgType: 'INVESTOR',
    orgData: invFirm,
    detailsList: [
      {
        label: 'Assets Under Management',
        value: invFirm?.assetsUnderManagement
          ? getlabelsByValues(
              [invFirm?.assetsUnderManagement],
              assets_under_management.options,
            )
          : '',
      },
      {
        label: 'Investor Type',
        value: invFirm?.investorType
          ? getlabelsByValues([invFirm?.investorType], investor_type.options)
          : '',
      },
      {
        label: 'Industries',
        value: getlabelsByValues(invFirm?.industries, industry_type.options),
      },
    ],
  };
}

export function convertDateToTimeStamp(dateValue: Date): Timestamp {
  return Timestamp.fromDate(dateValue);
}

export function convertDatesToFirestoreTimestamps<T>(obj: T): T {
  const result: any = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = (obj as any)[key];

      if (value instanceof Date) {
        result[key] = Timestamp.fromDate(value);
      } else if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        result[key] = convertDatesToFirestoreTimestamps(value); // Recursively convert nested objects
      } else {
        result[key] = value;
      }
    }
  }

  return result as T;
}

export function convertTimestampsToDates<T>(obj: T): T {
  const result: any = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = (obj as any)[key];

      if (value instanceof Timestamp) {
        result[key] = value.toDate();
      } else if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        result[key] = convertTimestampsToDates(value); // Recursively convert nested objects
      } else {
        result[key] = value;
      }
    }
  }

  return result as T;
}

export function readableTime(
  date?: Date | string,
  format: string = 'MMM DD, YYYY',
): string {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }

  // Assuming Timestamp is defined elsewhere in your code
  if (date instanceof Timestamp) {
    date = date.toDate();
  }

  if (!(date instanceof Date) || isNaN(date.getTime())) return '';

  return dayjs(date).format(format);
}

export function displayDateFormatter(
  date?: Date | string,
  format: string = 'MMM DD, YYYY',
): string {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (date instanceof Timestamp) {
    date = date.toDate();
  }
  // if(date instanceof dayjs.Dayjs){
  //   date = date.toDate();
  // };

  // if(date.toDate){
  //   date = date.toDate();
  // }
  if (!(date instanceof Date)) return '';
  console.log('dayjs(date).format(format)', dayjs(date).format(format));
  return dayjs(date).format(format);
}
